import React from "react";
import ReactTooltip from "react-tooltip";
import "./btn.css";

class Btn extends React.Component {
  _calcClassName() {
    let classname = "checkbox btn btn-primary";
    if (this.props.btn_width_xxs) classname += " btn_width_xxs";
    if (this.props.btn_width_s) classname += " btn_width_s";
    if (this.props.isChecked) classname += " isChecked";
    if (this.props.isBlue)
      classname += this.props.isChecked ? " isBlueChecked" : " isBlue";
    if (this.props.isRed)
      classname += this.props.isChecked ? " isRedChecked" : " isRed";
    if (this.props.isGreen)
      classname += this.props.isChecked ? " isGreenChecked" : " isGreen";
    if (this.props.isDarkGray)
      classname += this.props.isChecked ? " isDarkGrayChecked" : " isDarkGray";
    if (this.props.isDisabled) classname += " disabled_mc";
    if (this.props.extraClassName) classname += " " + this.props.extraClassName;

    return classname;
  }

  render() {
    return (
      <>
        <ReactTooltip
          id={this.props.idTooltip}
          effect="float"
          place="bottom"
          delayShow={100}
          disable={!this.props.tipsEnabled}
          className="tooltip-react"
        />
        <button
          checked={this.props.isChecked}
          data-tip={this.props.dataTip}
          name={this.props.name}
          type="radio"
          className={this._calcClassName()}
          onClick={(e) => this.props.onClick(e)}
        >
          {this.props.value}
        </button>
      </>
    );
  }
}

export default Btn;
