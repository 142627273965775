import React from "react";
import { withNamespaces } from "react-i18next";
import InputBox from "../../../_components/InputBox";
import Btn from "../../../_components/Btn";
import { calcTwbFromDb } from "../../../../tools/calc_thermodynamic.js";
import "./condLeft.css";
const K_cond = require("../../../../assets/support/__k_cond"),
  K_min_max_dec = require("../../../../assets/support/__k_min_max_dec"),
  K_backend = require("../../../../assets/support/__k_backend"),
  K_varie = require("../../../../assets/support/__k_varie");

class CondLeft extends React.Component {
  componentDidMount() {
    this.props.cond.esp = parseFloat(this.props.cond.esp).toFixed(
      K_min_max_dec.decEsp
    );
    this.props.cond.altitu = parseFloat(this.props.cond.altitu).toFixed(
      K_min_max_dec.decAltitu
    );
  }

  _getPresetList() {
    return this.props.visSett.presetList;
  }

  _setCmode() {
    let cond2 = this.props.cond;

    if (this.props.visSett.H4Pipes === true) {
      if (this.props.visSett.CMode === true)
        cond2.modoResa = K_backend.modoResaH4p;
      else cond2.modoResa = K_backend.modoResaCH4p;

      this.props.setCond(cond2);
      return;
    }

    if (this.props.visSett.HMode === true) {
      if (this.props.visSett.CMode === true)
        cond2.modoResa = K_backend.modoResaH2p;
      else cond2.modoResa = K_backend.modoResaCH2p;
    }

    if (this.props.visSett.HMode === false)
      cond2.modoResa = K_backend.modoResaC;

    this.props.setCond(cond2);
  }

  _setHmode() {
    let cond2 = this.props.cond;

    if (this.props.visSett.H4Pipes === true) {
      // Check if is possible to deselect the auxiliary coil
      if (this.props.GUIsel.disVal.battA.indexOf(0) > -1) {
        let maccSelData2 = this.props.GUIsel;
        maccSelData2.btnSel.battA = new Array(
          maccSelData2.btnSel.battA.length
        ).fill(false);
        maccSelData2.btnSel.battA[0] = true;
        this.props.updateCatalog(maccSelData2);
        cond2.modoResa = this.props.GUIsel.disVal.modiResa[0];
        this.props.setCond(cond2);
        return;
      } else return;
    }

    if (this.props.visSett.CMode === true) {
      if (this.props.visSett.HMode === true)
        cond2.modoResa = K_backend.modoResaC;
      else cond2.modoResa = K_backend.modoResaCH2p;
    }
    if (this.props.visSett.CMode === false)
      cond2.modoResa = K_backend.modoResaH2p;
    this.props.setCond(cond2);
  }

  _setPreset(idx) {
    let taBuInCtmp,
      cond2 = this.props.cond;

    switch (this.props.visSett.presetList[idx]) {
      case "labelNone":
        break;
      case "labelC":
        cond2.taInC = parseFloat(K_cond.std_taInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.std_rhInC).toFixed(
          K_min_max_dec.decRh
        );
        taBuInCtmp = calcTwbFromDb(
          cond2.taInC,
          cond2.rhInC,
          cond2.altitu
        );
        cond2.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);
        cond2.twInC = parseFloat(K_cond.std_twInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.std_twOutC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("C");

        if (
          this.props.cond.modoResa === K_backend.modoResaCH2p ||
          this.props.cond.modoResa ===
            K_backend.modoResaCH2pFlowC ||
          this.props.cond.modoResa === K_backend.modoResaH2p
        )
          cond2.modoResa = K_backend.modoResaCH2p;
        else if (
          this.props.cond.modoResa === K_backend.modoResaCH4p ||
          this.props.cond.modoResa === K_backend.modoResaH4p
        )
          cond2.modoResa = K_backend.modoResaCH4p;
        else cond2.modoResa = K_backend.modoResaC;

        break;
      case "labelCNS1":
        cond2.taInC = parseFloat(K_cond.ns1_taInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.ns1_rhInC).toFixed(
          K_min_max_dec.decRh
        );
        taBuInCtmp = calcTwbFromDb(
          cond2.taInC,
          cond2.rhInC,
          cond2.altitu
        );
        cond2.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);
        cond2.twInC = parseFloat(K_cond.ns1_twInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.ns1_twOutC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("C");

        if (
          this.props.cond.modoResa === K_backend.modoResaCH2p ||
          this.props.cond.modoResa ===
            K_backend.modoResaCH2pFlowC ||
          this.props.cond.modoResa === K_backend.modoResaH2p
        )
          cond2.modoResa = K_backend.modoResaCH2p;
        else if (
          this.props.cond.modoResa === K_backend.modoResaCH4p ||
          this.props.cond.modoResa === K_backend.modoResaH4p
        )
          cond2.modoResa = K_backend.modoResaCH4p;
        else cond2.modoResa = K_backend.modoResaC;

        break;
      case "labelCNS2":
        cond2.taInC = parseFloat(K_cond.ns2_taInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.ns2_rhInC).toFixed(
          K_min_max_dec.decRh
        );
        taBuInCtmp = calcTwbFromDb(
          cond2.taInC,
          cond2.rhInC,
          cond2.altitu
        );
        cond2.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);
        cond2.twInC = parseFloat(K_cond.ns2_twInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.ns2_twOutC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("C");

        if (
          this.props.cond.modoResa === K_backend.modoResaCH2p ||
          this.props.cond.modoResa ===
            K_backend.modoResaCH2pFlowC ||
          this.props.cond.modoResa === K_backend.modoResaH2p
        )
          cond2.modoResa = K_backend.modoResaCH2p;
        else if (
          this.props.cond.modoResa === K_backend.modoResaCH4p ||
          this.props.cond.modoResa === K_backend.modoResaH4p
        )
          cond2.modoResa = K_backend.modoResaCH4p;
        else cond2.modoResa = K_backend.modoResaC;

        break;
      case "labelCNS3":
        cond2.taInC = parseFloat(K_cond.ns3_taInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.ns3_rhInC).toFixed(
          K_min_max_dec.decRh
        );
        taBuInCtmp = calcTwbFromDb(
          cond2.taInC,
          cond2.rhInC,
          cond2.altitu
        );
        cond2.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);
        cond2.twInC = parseFloat(K_cond.ns3_twInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.ns3_twOutC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("C");

        if (
          this.props.cond.modoResa === K_backend.modoResaCH2p ||
          this.props.cond.modoResa ===
            K_backend.modoResaCH2pFlowC ||
          this.props.cond.modoResa === K_backend.modoResaH2p
        )
          cond2.modoResa = K_backend.modoResaCH2p;
        else if (
          this.props.cond.modoResa === K_backend.modoResaCH4p ||
          this.props.cond.modoResa === K_backend.modoResaH4p
        )
          cond2.modoResa = K_backend.modoResaCH4p;
        else cond2.modoResa = K_backend.modoResaC;

        break;
      case "labelDC":
        cond2.taInC = parseFloat(K_cond.std_taInDC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.std_rhInDC).toFixed(
          K_min_max_dec.decRh
        );
        taBuInCtmp = calcTwbFromDb(
          cond2.taInC,
          cond2.rhInC,
          cond2.altitu
        );
        cond2.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);
        cond2.twInC = parseFloat(K_cond.std_twInDC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.std_twOutDC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("C");

        cond2.modoResa = K_backend.modoResaC;

        break;
      case "labelDCns1":
        cond2.taInC = parseFloat(K_cond.ns1_taInDC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.ns1_rhInDC).toFixed(
          K_min_max_dec.decRh
        );
        taBuInCtmp = calcTwbFromDb(
          cond2.taInC,
          cond2.rhInC,
          cond2.altitu
        );
        cond2.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);
        cond2.twInC = parseFloat(K_cond.ns1_twInDC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.ns1_twOutDC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("C");

        cond2.modoResa = K_backend.modoResaC;

        break;
      case "labelH4Pipes":
        cond2.taInH = parseFloat(K_cond.dflt_taInH).toFixed(
          K_min_max_dec.decT
        );
        cond2.twInH = parseFloat(K_cond.std_twInH4).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutH = parseFloat(K_cond.std_twOutH4).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwH = 0;
        this.props.blinkGroup("H");

        if (
          this.props.cond.modoResa === K_backend.modoResaC ||
          this.props.cond.modoResa === K_backend.modoResaCH4p
        )
          cond2.modoResa = K_backend.modoResaCH4p;
        else cond2.modoResa = K_backend.modoResaH4p;

        break;
      case "labelH4PipesNS1":
        cond2.taInH = parseFloat(K_cond.dflt_taInH).toFixed(
          K_min_max_dec.decT
        );
        cond2.twInH = parseFloat(K_cond.ns1_twInH4).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutH = parseFloat(K_cond.ns1_twOutH4).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwH = 0;
        this.props.blinkGroup("H");

        if (
          this.props.cond.modoResa === K_backend.modoResaC ||
          this.props.cond.modoResa === K_backend.modoResaCH4p
        )
          cond2.modoResa = K_backend.modoResaCH4p;
        else cond2.modoResa = K_backend.modoResaH4p;

        break;
      case "labelH2Pipes":
        cond2.taInH = parseFloat(K_cond.dflt_taInH).toFixed(
          K_min_max_dec.decT
        );
        cond2.twInH = parseFloat(K_cond.std_twInH).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutH = parseFloat(K_cond.std_twOutH).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwH = 0;
        this.props.blinkGroup("H");

        if (
          this.props.cond.modoResa === K_backend.modoResaC ||
          this.props.cond.modoResa === K_backend.modoResaCH2p ||
          this.props.cond.modoResa === K_backend.modoResaCH2pFlowC
        )
          cond2.modoResa = K_backend.modoResaCH2p;
        else cond2.modoResa = K_backend.modoResaH2p;
        break;
      case "labelH2PipesNS1":
        cond2.taInH = parseFloat(K_cond.dflt_taInH).toFixed(
          K_min_max_dec.decT
        );
        cond2.twInH = parseFloat(K_cond.ns1_twInH).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutH = parseFloat(K_cond.ns1_twOutH).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwH = 0;
        cond2.taInC = parseFloat(K_cond.std_taInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.rhInC = parseFloat(K_cond.std_rhInC).toFixed(
          K_min_max_dec.decRh
        );
        cond2.twInC = parseFloat(K_cond.std_twInC).toFixed(
          K_min_max_dec.decT
        );
        cond2.twOutC = parseFloat(K_cond.std_twOutC).toFixed(
          K_min_max_dec.decT
        );
        cond2.qwC = 0;
        this.props.blinkGroup("flowC");

        cond2.modoResa = K_backend.modoResaCH2pFlowC;
        break;
      default:
        break;
    }

    this.props.setCond(cond2);
  }

  onChange(e) {
    let cond = this.props.cond;

    switch (e.target.name) {
      case "esp":
        cond.esp = e.target.value;
        break;
      case "altitu":
        cond.altitu = e.target.value;
        break;
      default:
        break;
    }

    this.props.setCond(cond);
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      let cond = this.props.cond;

      if (!this.props.validator.isValid(e.target.name, e.target.value)) {
        if (
          !(
            e.target.value === undefined ||
            isNaN(e.target.value) ||
            e.target.value === ""
          )
        )
          this.props.displayPopUpSms(this.props.t("err_" + e.target.name));

        cond.esp = this.props.validator.fallBackValue("esp");
        cond.altitu = this.props.validator.fallBackValue("altitu");
        this.props.setCond(cond);

        return;
      }

      switch (e.target.name) {
        case "esp":
          cond.esp = parseFloat(e.target.value).toFixed(K_min_max_dec.decEsp);
          this.props.validator.setFallBackValue("esp", cond.esp);
          break;
        case "altitu":
          cond.altitu = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decAltitu
          );
          this.props.validator.setFallBackValue("altitu", cond.altitu);
          break;
        default:
          break;
      }

      this.props.setCond(cond);
    }
  }

  render() {
    let presetOptions = [];

    this._getPresetList().forEach((el, idx) => {
      let elDaTrad = "";

      switch (el) {
        case "labelNone":
          elDaTrad = K_varie.dataND;
          break;
        case "labelC":
          elDaTrad = this.props.t("cooling") + " STD";
          break;
        case "labelCNS1":
          elDaTrad = this.props.t("cooling") + " NS1";
          break;
        case "labelCNS2":
          elDaTrad = this.props.t("cooling") + " NS2";
          break;
        case "labelCNS3":
          elDaTrad = this.props.t("cooling") + " NS3";
          break;
        case "labelDC":
          elDaTrad = "District Cooling STD";
          break;
        case "labelDCns1":
          elDaTrad = "District Cooling NS1";
          break;
        case "labelH4Pipes":
          elDaTrad =
            this.props.t("heating") + " " + this.props.t("pipes4") + " STD";
          break;
        case "labelH4PipesNS1":
          elDaTrad =
            this.props.t("heating") + " " + this.props.t("pipes4") + " NS1";
          break;
        case "labelH2Pipes":
          elDaTrad =
            this.props.t("heating") + " " + this.props.t("pipes2") + " STD";
          break;
        case "labelH2PipesNS1":
          elDaTrad =
            this.props.t("heating") + " " + this.props.t("pipes2") + " NS1";
          break;
        default:
          elDaTrad = "";
          break;
      }

      presetOptions.push(
        <option key={"presetOption" + idx} value={idx}>
          {elDaTrad}
        </option>
      );
    });

    let presetComponent = (
      <>
        <select
          id="selPreset"
          onChange={(e) => this._setPreset(e.target.value)}
        >
          {presetOptions}
        </select>
      </>
    );

    return (
      <>
        <div id="rcOpCond" className="cell">
          <div className="cell-title">
            <h4>{this.props.t("opOperating").toUpperCase()}</h4>
          </div>
          <ul className="list-group">
            <li className="list-group-item text-center">
              <div
                id="btnRaff"
                className={
                  this.props.visSett.blinkC || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
              >
                <Btn
                  extraClassName="btn_width_xl btn_height_s"
                  isBlue={true}
                  isChecked={this.props.visSett.CMode}
                  isDisabled={!this.props.visSett.isCModePossible}
                  value={this.props.t("cooling")}
                  onClick={() => this._setCmode()}
                />
              </div>
            </li>
            <li id="formRisc" className="list-group-item text-center">
              <div
                id="btnRisc"
                className={
                  this.props.visSett.blinkC || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
              >
                <Btn
                  extraClassName="btn_width_xl btn_height_s"
                  isChecked={
                    this.props.visSett.HMode || this.props.visSett.H4Pipes
                  }
                  isDisabled={!this.props.visSett.isHModePossible}
                  isRed={true}
                  value={
                    this.props.t("heating") +
                    " " +
                    (this.props.visSett.H4Pipes
                      ? this.props.t("pipes4")
                      : this.props.t("pipes2"))
                  }
                  onClick={() => this._setHmode()}
                />
              </div>
            </li>
            <li id="euroCond" className="list-group-item text-center">
              <div>{this.props.t("euroCond").toUpperCase()}</div>
              {presetComponent}
            </li>
            <li className="list-group-item text-center esp-text">
              <InputBox
                className="text-center"
                key="esp"
                isDisabled={!this.props.visSett.isEspEnabled}
                name="esp"
                type="text"
                value={this.props.cond.esp}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
              <label
                data-tip={this.props.t("tip_esp")}
                className="esp-text"
              >
                {" " + this.props.t("esp_abbr") + " [Pa]"}
              </label>
            </li>
            <li className="list-group-item">
              <InputBox
                type="text"
                className="text-center"
                key="altitu"
                name="altitu"
                value={this.props.cond.altitu}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
              <label data-tip={this.props.t("tip_altitude")}>
                {this.props.t("altitude") + " [m]"}
              </label>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withNamespaces()(CondLeft);
