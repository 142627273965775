import React from "react";
import CondLeft from "./CondLeft";
import CondCenter from "./CondCenter";
import CondRight from "./CondRight";
import "./cond.css";

class Cond extends React.Component {
  blinkGroup(blinkOption) {
    let visSett2 = this.props.visSett;

    switch (blinkOption) {
      case "reset":
        visSett2.blinkH = false;
        visSett2.blinkC = false;
        visSett2.blinkFlowC = false;
        this.props.setVisSett(visSett2);
        return;
      case "H":
        visSett2.blinkH = true;
        visSett2.blinkC = false;
        visSett2.blinkFlowC = false;
        break;
      case "C":
        visSett2.blinkH = false;
        visSett2.blinkC = true;
        visSett2.blinkFlowC = false;
        break;
      case "flowC":
        visSett2.blinkH = false;
        visSett2.blinkC = false;
        visSett2.blinkFlowC = true;
        break;
      default:
        return;
    }

    this.props.setVisSett(visSett2);

    setTimeout(() => this.blinkGroup("reset"), 2000);
  }

  render() {
    return (
      <>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <CondLeft
            cond={this.props.cond}
            GUIsel={this.props.GUIsel}
            usrSett={this.props.usrSett}
            validator={this.props.validator}
            visSett={this.props.visSett}
            blinkGroup={b => this.blinkGroup(b)}
            displayPopUpSms={m => this.props.displayPopUpSms(m)}
            setCond={c => this.props.setCond(c)}
            updateCatalog={m => this.props.updateCatalog(m)}
          />
        </div>
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8">
          <CondCenter
            cond={this.props.cond}
            GUIsel={this.props.GUIsel}
            usrSett={this.props.usrSett}
            validator={this.props.validator}
            visSett={this.props.visSett}
            blinkGroup={b => this.blinkGroup(b)}
            displayPopUpSms={m => this.props.displayPopUpSms(m)}
            setCond={c => this.props.setCond(c)}
            setVisSett={v => this.props.setVisSett(v)}
            updateCatalog={m => this.props.updateCatalog(m)}
          />
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <CondRight
            cond={this.props.cond}
            GUIsel={this.props.GUIsel}
            usrSett={this.props.usrSett}
            validator={this.props.validator}
            visSett={this.props.visSett}
            displayPopUpSms={m => this.props.displayPopUpSms(m)}
            setCond={c => this.props.setCond(c)}
            setVisSett={v => this.props.setVisSett(v)}
          />
        </div>
      </>
    );
  }
}

export default Cond;
