import React from "react";
import { withNamespaces } from "react-i18next";
import InputBox from "../../../../_components/InputBox";
import Btn from "../../../../_components/Btn";
import {
  calcTwbFromDb,
  calcRH,
  calcTwbFromWb,
  calcMiscC,
} from "../../../../../tools/calc_thermodynamic.js";
const K_cond = require("../../../../../assets/support/__k_cond"),
  K_min_max_dec = require("../../../../../assets/support/__k_min_max_dec");

class TableCAirSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airMixVis: false,
      percentAirExtC: parseFloat(K_cond.dflt_percentAirExtC).toFixed(
        K_min_max_dec.decPercAir
      ),
      taExtC: parseFloat(K_cond.dflt_taExtC).toFixed(K_min_max_dec.decT),
      rhExtC: parseFloat(K_cond.dflt_rhExtC).toFixed(K_min_max_dec.decRh),
      taIntC: parseFloat(K_cond.dflt_taIntC).toFixed(K_min_max_dec.decT),
      rhIntC: parseFloat(K_cond.dflt_rhIntC).toFixed(K_min_max_dec.decRh),
    };
  }

  componentDidMount() {
    this.props.cond.taInC = parseFloat(this.props.cond.taInC).toFixed(
      K_min_max_dec.decT
    );
    this.props.cond.taBuInC = parseFloat(this.props.cond.taBuInC).toFixed(
      K_min_max_dec.decT
    );
    this.props.cond.rhInC = parseFloat(this.props.cond.rhInC).toFixed(
      K_min_max_dec.decRh
    );
  }

  _airMixClick() {
    this.setState({ airMixVis: !this.state.airMixVis });
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      let cond = this.props.cond,
        outputVal,
        taExtCtmp,
        percentAirExtCtmp,
        rhExtCtmp,
        taIntCtmp,
        rhIntCtmp,
        taBuInCtmp,
        rhInCtmp;

      // Input Check
      if (!this.props.validator.isValid(e.target.name, e.target.value)) {
        if (
          !(
            e.target.value === undefined ||
            isNaN(e.target.value) ||
            e.target.value === ""
          )
        ) {
          this.props.displayPopUpSms(this.props.t("err_" + e.target.name));
        }

        cond.taInC = this.props.validator.fallBackValue("taInC");
        cond.taBuInC = this.props.validator.fallBackValue("taBuInC");
        cond.rhInC = this.props.validator.fallBackValue("rhInC");
        percentAirExtCtmp = this.props.validator.fallBackValue(
          "percentAirExtC"
        );
        taExtCtmp = this.props.validator.fallBackValue("taExtC");
        rhExtCtmp = this.props.validator.fallBackValue("rhExtC");
        taIntCtmp = this.props.validator.fallBackValue("taIntC");
        rhIntCtmp = this.props.validator.fallBackValue("rhIntC");

        this.props.setCond(cond);
        this.setState({ percentAirExtC: percentAirExtCtmp });
        this.setState({ taExtC: taExtCtmp });
        this.setState({ rhExtC: rhExtCtmp });
        this.setState({ rhIntC: rhIntCtmp });
        this.setState({ taIntC: taIntCtmp });

        return;
      }

      switch (e.target.name) {
        case "taInC":
          cond.taInC = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decT
          );
          taBuInCtmp = calcTwbFromDb(cond.taInC, cond.rhInC, cond.altitu);
          cond.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);

          this.props.validator.setFallBackValue("taInC", cond.taInC);
          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.setCond(cond);

          break;
        case "taBuInC":
          cond.taBuInC = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decT
          );
          rhInCtmp = calcRH(cond.taInC, cond.taBuInC, cond.altitu);
          cond.rhInC = parseFloat(rhInCtmp).toFixed(K_min_max_dec.decRh);

          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.setCond(cond);

          break;
        case "rhInC":
          cond.rhInC = parseFloat(e.target.value).toFixed(K_min_max_dec.decRh);
          taBuInCtmp = calcTwbFromWb(cond.taInC, cond.rhInC, cond.altitu);
          cond.taBuInC = parseFloat(taBuInCtmp).toFixed(K_min_max_dec.decT);

          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.setCond(cond);

          break;
        case "percentAirExtC":
          outputVal = calcMiscC(
            this.state.percentAirExtC,
            this.state.taIntC,
            this.state.taExtC,
            this.state.rhIntC,
            this.state.rhExtC,
            this.props.cond.altitu
          );
          cond.taInC = parseFloat(outputVal.taBa).toFixed(
            K_min_max_dec.decT
          );
          cond.taBuInC = parseFloat(outputVal.taBu).toFixed(
            K_min_max_dec.decT
          );
          cond.rhInC = parseFloat(outputVal.rh).toFixed(K_min_max_dec.decRh);

          this.props.setCond(cond);
          this.setState({
            percentAirExtC: parseFloat(this.state.percentAirExtC).toFixed(
              K_min_max_dec.decPercAir
            ),
          });
          this.props.validator.setFallBackValue("taInC", cond.taInC);
          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.validator.setFallBackValue(
            "percentAirExtC",
            this.state.percentAirExtC
          );

          break;
        case "taExtC":
          outputVal = calcMiscC(
            this.state.percentAirExtC,
            this.state.taIntC,
            this.state.taExtC,
            this.state.rhIntC,
            this.state.rhExtC,
            this.props.cond.altitu
          );
          cond.taInC = parseFloat(outputVal.taBa).toFixed(
            K_min_max_dec.decT
          );
          cond.taBuInC = parseFloat(outputVal.taBu).toFixed(
            K_min_max_dec.decT
          );
          cond.rhInC = parseFloat(outputVal.rh).toFixed(K_min_max_dec.decRh);

          this.setState({
            taExtC: parseFloat(this.state.taExtC).toFixed(
              K_min_max_dec.decT
            ),
          });
          this.props.setCond(cond);
          this.props.validator.setFallBackValue("taInC", cond.taInC);
          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.validator.setFallBackValue("taExtC", this.state.taExtC);

          break;
        case "rhExtC":
          outputVal = calcMiscC(
            this.state.percentAirExtC,
            this.state.taIntC,
            this.state.taExtC,
            this.state.rhIntC,
            this.state.rhExtC,
            this.props.cond.altitu
          );
          cond.taInC = parseFloat(outputVal.taBa).toFixed(
            K_min_max_dec.decT
          );
          cond.taBuInC = parseFloat(outputVal.taBu).toFixed(
            K_min_max_dec.decT
          );
          cond.rhInC = parseFloat(outputVal.rh).toFixed(K_min_max_dec.decRh);

          this.setState({
            rhExtC: parseFloat(this.state.rhExtC).toFixed(K_min_max_dec.decRh),
          });
          this.props.setCond(cond);
          this.props.validator.setFallBackValue("taInC", cond.taInC);
          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.validator.setFallBackValue("rhExtC", this.state.rhExtC);

          break;
        case "taIntC":
          outputVal = calcMiscC(
            this.state.percentAirExtC,
            this.state.taIntC,
            this.state.taExtC,
            this.state.rhIntC,
            this.state.rhExtC,
            this.props.cond.altitu
          );
          cond.taInC = parseFloat(outputVal.taBa).toFixed(
            K_min_max_dec.decT
          );
          cond.taBuInC = parseFloat(outputVal.taBu).toFixed(
            K_min_max_dec.decT
          );
          cond.rhInC = parseFloat(outputVal.rh).toFixed(K_min_max_dec.decRh);

          this.setState({
            taIntC: parseFloat(this.state.taIntC).toFixed(
              K_min_max_dec.decT
            ),
          });
          this.props.setCond(cond);
          this.props.validator.setFallBackValue("taInC", cond.taInC);
          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.validator.setFallBackValue("taIntC", this.state.taIntC);

          break;
        case "rhIntC":
          outputVal = calcMiscC(
            this.state.percentAirExtC,
            this.state.taIntC,
            this.state.taExtC,
            this.state.rhIntC,
            this.state.rhExtC,
            this.props.cond.altitu
          );
          cond.taInC = parseFloat(outputVal.taBa).toFixed(
            K_min_max_dec.decT
          );
          cond.taBuInC = parseFloat(outputVal.taBu).toFixed(
            K_min_max_dec.decT
          );
          cond.rhInC = parseFloat(outputVal.rh).toFixed(K_min_max_dec.decRh);

          this.setState({
            rhIntC: parseFloat(this.state.rhIntC).toFixed(K_min_max_dec.decRh),
          });
          this.props.setCond(cond);
          this.props.validator.setFallBackValue("taInC", cond.taInC);
          this.props.validator.setFallBackValue("taBuInC", cond.taBuInC);
          this.props.validator.setFallBackValue("rhInC", cond.rhInC);
          this.props.validator.setFallBackValue("rhIntC", this.state.rhIntC);

          break;

        default:
          break;
      }
    }
  }

  onChange(e) {
    if (isNaN(e.target.value)) return;

    let cond = this.props.cond;
    switch (e.target.name) {
      case "taInC":
        cond.taInC = e.target.value;
        this.props.setCond(cond);
        break;

      case "taBuInC":
        cond.taBuInC = e.target.value;
        this.props.setCond(cond);
        break;

      case "rhInC":
        cond.rhInC = e.target.value;
        this.props.setCond(cond);
        break;

      case "percentAirExtC":
        this.setState({ percentAirExtC: e.target.value });
        break;

      case "taExtC":
        this.setState({ taExtC: e.target.value });
        break;

      case "rhExtC":
        this.setState({ rhExtC: e.target.value });
        break;

      case "taIntC":
        this.setState({ taIntC: e.target.value });
        break;

      case "rhIntC":
        this.setState({ rhIntC: e.target.value });
        break;

      default:
        break;
    }
  }

  render() {
    let airMixTableTitle, airMixTableBody, tableAirSide;

    if (this.state.airMixVis) {
      airMixTableTitle = (
        <>
          <td className="text-center">
            <span>{this.props.t("airInt")}</span>
          </td>
          <td className="text-center">
            <span>{this.props.t("airExt")}</span>
          </td>
        </>
      );
      airMixTableBody = (
        <>
          <tr>
            <td>{this.props.t("airExt") + "  [%]"}</td>
            <td />
            <td className="text-center">
              <InputBox
                type="text"
                className="text-center"
                key="percentAirExtC"
                name="percentAirExtC"
                value={this.state.percentAirExtC}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("tdb_abbr") + "  [°C]"}</td>
            <td className="text-center">
              <InputBox
                key="taIntC"
                name="taIntC"
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
                type="text"
                value={this.state.taIntC}
                className="text-center"
              />
            </td>
            <td className="text-center">
              <InputBox
                type="text"
                className="text-center"
                name="taExtC"
                key="taExtC"
                value={this.state.taExtC}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("rh") + "  [%]"}</td>
            <td className="text-center">
              <InputBox
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
                name="rhIntC"
                key="rhIntC"
                value={this.state.rhIntC}
                type="text"
                className="text-center"
              />
            </td>
            <td className="text-center">
              <InputBox
                type="text"
                className="text-center"
                name="rhExtC"
                key="rhExtC"
                value={this.state.rhExtC}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
            </td>
          </tr>
        </>
      );
    }

    tableAirSide = (
      <>
        <table id="tableAirFred" className="table">
          <caption className="text-center table-caption">
            {this.props.title}
          </caption>
          <tbody>
            <tr>
              <td>
                <Btn
                  value={this.props.t("airMix")}
                  onClick={() => this._airMixClick()}
                  isChecked={this.state.airMixVis}
                  isBlue={true}
                  extraClassName="btn_width_xs"
                />
              </td>
              {airMixTableTitle}
            </tr>
            {airMixTableBody}
            <tr>
              <td>{this.props.t("tdb_abbr") + " [°C]"}</td>
              <td className="text-center" colSpan="2">
                <InputBox
                  className={this.props.visSett.blinkC ? "blink" : ""}
                  key="taInC"
                  name="taInC"
                  onChange={(e) => this.onChange(e)}
                  onKeyPress={(e) => this.onKeyPress(e)}
                  value={this.props.cond.taInC}
                  type="text"
                  isDisabled={this.state.airMixVis}
                />
              </td>
            </tr>
            <tr>
              <td>{this.props.t("twb_abbr") + " [°C]"}</td>
              <td className="text-center" colSpan="2">
                <InputBox
                  className={this.props.visSett.blinkC ? "blink" : ""}
                  key="taBuInC"
                  name="taBuInC"
                  onChange={(e) => this.onChange(e)}
                  onKeyPress={(e) => this.onKeyPress(e)}
                  value={this.props.cond.taBuInC}
                  type="text"
                  isDisabled={this.state.airMixVis}
                />
              </td>
            </tr>
            <tr>
              <td>{this.props.t("rh") + " [%]"}</td>
              <td className="text-center" colSpan="2">
                <InputBox
                  className={this.props.visSett.blinkC ? "blink" : ""}
                  key="rhInC"
                  name="rhInC"
                  onChange={(e) => this.onChange(e)}
                  onKeyPress={(e) => this.onKeyPress(e)}
                  value={this.props.cond.rhInC}
                  type="text"
                  isDisabled={this.state.airMixVis}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );

    return tableAirSide;
  }
}

export default withNamespaces()(TableCAirSide);
