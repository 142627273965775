import React from "react";
import EllipsisText from "react-ellipsis-text";
import { withNamespaces } from "react-i18next";
import InputBox from "../../../_components/InputBox";
import "./condRight.css";
const K_min_max_dec = require("../../../../assets/support/__k_min_max_dec");

class CondRight extends React.Component {
  componentDidMount() {
    this.props.cond.roomLen = parseFloat(this.props.cond.roomLen).toFixed(
      K_min_max_dec.decMt
    );
    this.props.cond.roomWid = parseFloat(this.props.cond.roomWid).toFixed(
      K_min_max_dec.decMt
    );
    this.props.cond.roomHei = parseFloat(this.props.cond.roomHei).toFixed(
      K_min_max_dec.decMt
    );
    this.props.cond.tRiv = parseFloat(this.props.cond.tRiv).toFixed(
      K_min_max_dec.decTrev
    );
    this.props.cond.sabine = parseFloat(this.props.cond.sabine).toFixed(
      K_min_max_dec.decSab
    );
    this.props.cond.micSorg = parseFloat(this.props.cond.micSorg).toFixed(
      K_min_max_dec.decMicSrc
    );
  }

  _getSabin() {
    if (!this.props.visSett.isTrivDisplayed) return this.props.cond.sabine;
    else return "";
  }

  _getTriv() {
    if (this.props.visSett.isTrivDisplayed) return this.props.cond.tRiv;
    else return "";
  }

  onChange(e) {
    let visSett2 = this.props.visSett,
      cond = this.props.cond;

    switch (e.target.name) {
      case "roomLen":
        cond.roomLen = e.target.value;
        break;
      case "roomWid":
        cond.roomWid = e.target.value;
        break;
      case "roomHei":
        cond.roomHei = e.target.value;
        break;
      case "tRiv":
        cond.tRiv = e.target.value;
        visSett2.isTrivDisplayed = true;
        break;
      case "sabine":
        cond.sabine = e.target.value;
        visSett2.isTrivDisplayed = false;
        break;
      case "micSorg":
        cond.micSorg = e.target.value;
        break;
      default:
        break;
    }

    this.props.setVisSett(visSett2);
    this.props.setCond(cond);
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      let cond = this.props.cond,
        areaRoom =
          2 * cond.roomLen * cond.roomWid +
          2 * cond.roomWid * cond.roomHei +
          2 * cond.roomLen * cond.roomHei;

      if (cond.sabine > areaRoom) {
        cond.sabine = areaRoom;
        cond.sabine = this.props.validator.fallBackValue("sabine");
        this.props.displayPopUpSms(this.props.t("err_sabine2"));

        return;
      }

      // Input Check
      if (!this.props.validator.isValid(e.target.name, e.target.value)) {
        if (
          !(
            e.target.value === undefined ||
            isNaN(e.target.value) ||
            e.target.value === ""
          )
        )
          this.props.displayPopUpSms(this.props.t("err_" + e.target.name));

        cond.roomLen = this.props.validator.fallBackValue("roomLen");
        cond.roomWid = this.props.validator.fallBackValue("roomWid");
        cond.roomHei = this.props.validator.fallBackValue("roomHei");
        cond.tRiv = this.props.validator.fallBackValue("tRiv");
        cond.sabine = this.props.validator.fallBackValue("sabine");
        cond.micSorg = this.props.validator.fallBackValue("micSorg");
        this.props.setCond(cond);

        return;
      }

      switch (e.target.name) {
        case "roomLen":
          cond.roomLen = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decMt
          );
          this.props.validator.setFallBackValue("roomLen", cond.roomLen);
          break;
        case "roomWid":
          cond.roomWid = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decMt
          );
          this.props.validator.setFallBackValue("roomWid", cond.roomWid);
          break;
        case "roomHei":
          cond.roomHei = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decMt
          );
          this.props.validator.setFallBackValue("roomHei", cond.roomHei);
          break;
        case "tRiv":
          cond.tRiv = parseFloat(e.target.value).toFixed(K_min_max_dec.decTrev);
          this.props.validator.setFallBackValue("tRiv", cond.tRiv);
          break;
        case "sabine":
          cond.sabine = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decSab
          );
          this.props.validator.setFallBackValue("sabine", cond.sabine);
          break;
        case "micSorg":
          cond.micSorg = parseFloat(e.target.value).toFixed(
            K_min_max_dec.decMicSrc
          );
          this.props.validator.setFallBackValue("micSorg", cond.micSorg);
          break;
        default:
          break;
      }

      this.props.setCond(cond);
    }
  }

  render() {
    let soundPtitle =
      this.props.usrSett.lang === "ge" ? (
        <EllipsisText
          text={this.props.t("soundPcalc").toUpperCase()}
          length={"18"}
        />
      ) : (
        this.props.t("soundPcalc").toUpperCase()
      );

    return (
      <>
        <div id="rcOpRes" className="cell">
          <div className="cell-title">
            <h4>{soundPtitle}</h4>
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              <div id="formLung">
                <label data-tip={this.props.t("roomLen")}>
                  <InputBox
                    className="text-center"
                    key="roomLen"
                    name="roomLen"
                    onChange={(e) => this.onChange(e)}
                    onKeyPress={(e) => this.onKeyPress(e)}
                    value={this.props.cond.roomLen}
                    type="text"
                  />
                </label>
              </div>
              <span className="lax">{"x"}</span>
              <div id="formLarg">
                <label data-tip={this.props.t("roomWid")}>
                  <InputBox
                    type="text"
                    className="text-center"
                    key="roomWid"
                    name="roomWid"
                    value={this.props.cond.roomWid}
                    onChange={(e) => this.onChange(e)}
                    onKeyPress={(e) => this.onKeyPress(e)}
                  />
                </label>
              </div>
              <span className="lax">{"x"}</span>
              <div id="formAlte">
                <label data-tip={this.props.t("roomHei")}>
                  <InputBox
                    type="text"
                    className="text-center"
                    key="roomHei"
                    name="roomHei"
                    value={this.props.cond.roomHei}
                    onChange={(e) => this.onChange(e)}
                    onKeyPress={(e) => this.onKeyPress(e)}
                  />
                  {" [m]"}
                </label>
              </div>
            </li>
            <li className="list-group-item">
              <InputBox
                type="text"
                className="text-center"
                key="tRiv"
                name="tRiv"
                value={this._getTriv()}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
              <label>{" " + this.props.t("revTime") + " [s]"}</label>
            </li>
            <li className="list-group-item">
              <InputBox
                type="text"
                className="text-center"
                key="sabine"
                name="sabine"
                value={this._getSabin()}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
              <label>{" [m\u00B2 Sabine]"}</label>
            </li>
            <li id="dMic" className="list-group-item">
              <InputBox
                type="text"
                className="text-center"
                key="micSorg"
                name="micSorg"
                value={this.props.cond.micSorg}
                onChange={(e) => this.onChange(e)}
                onKeyPress={(e) => this.onKeyPress(e)}
              />
              <label>{this.props.t("micSrc") + " [m]"}</label>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default withNamespaces()(CondRight);
