import React from "react";
import { withNamespaces } from "react-i18next";
import Btn from "../../_components/Btn";
const K_cond = require("../../../assets/support/__k_cond"),
  K_min_max_dec = require("../../../assets/support/__k_min_max_dec");

class ComputeBtn extends React.Component {
  _btnClick() {
    // controllo gli inputs
    // twOutC < twInC
    if (
      this.props.visSett.isTwOutCdisplayed &&
      this.props.cond.twOutC - this.props.cond.twInC <= 0
    ) {
      this.props.displayPopUpSms(this.props.t("err_twOutInC"));

      return;
    }
    // twH < 75 per EBH e EDS
    if (
      ((this.props.config.isEura && this.props.GUIsel.btnSel.range[6]) ||
        (!this.props.config.isEura && this.props.GUIsel.btnSel.range[5])) &&
      this.props.visSett.isTwOutHdisplayed &&
      (this.props.cond.twOutH - K_min_max_dec.maxTwH75 > 0 ||
        this.props.cond.twInH - K_min_max_dec.maxTwH75 > 0)
    ) {
      this.props.displayPopUpSms(this.props.t("err_twH75"));

      return;
    }
    // twOutH > twInH
    if (
      this.props.visSett.isTwOutHdisplayed &&
      this.props.cond.twOutH - this.props.cond.twInH >= 0
    ) {
      this.props.displayPopUpSms(this.props.t("err_twOutInH"));

      return;
    }
    // 3 < deltaTC < 10
    if (
      this.props.visSett.isTwOutCdisplayed &&
      !this.props.validator.isValid(
        "deltaTC",
        this.props.cond.twOutC - this.props.cond.twInC
      )
    ) {
      this.props.displayPopUpSms(
        this.props.t("err_delta") +
          " " +
          K_min_max_dec.minDeltaTC +
          " " +
          this.props.t("and") +
          " " +
          K_min_max_dec.maxDeltaTC +
          " [°C] " +
          "(" +
          this.props.t("cooling") +
          ")"
      );

      return;
    }
    // 3 < deltaTH < 25
    if (
      this.props.visSett.isTwOutHdisplayed &&
      !this.props.validator.isValid(
        "deltaTH",
        this.props.cond.twInH - this.props.cond.twOutH
      )
    ) {
      this.props.displayPopUpSms(
        this.props.t("err_delta") +
          " " +
          K_min_max_dec.minDeltaTH +
          " " +
          this.props.t("and") +
          " " +
          K_min_max_dec.maxDeltaTH +
          " [°C] " +
          "(" +
          this.props.t("heating") +
          ")"
      );

      return;
    }
    // taInC < TwOutC
    if (
      this.props.visSett.isTwOutCdisplayed &&
      this.props.cond.taInC - this.props.cond.twOutC < 0
    ) {
      this.props.displayPopUpSms(this.props.t("err_ta_twC"));

      return;
    }
    // taInH > TwOutH
    if (
      this.props.visSett.isTwOutHdisplayed &&
      this.props.cond.twOutH - this.props.cond.taInH < 0
    ) {
      this.props.displayPopUpSms(this.props.t("err_ta_twH"));

      return;
    }

    let visSett2 = this.props.visSett;
    visSett2.resultsComputedOnce = true;
    this.props.setVisSett(visSett2);

    this.props.toggleOverlay();

    (async () => {
      let idsSelMacc = this.props.catalog.getIdSelMacc(this.props.GUIsel),
        cond2 = this.props.cond,
        visSett2 = this.props.visSett;

      if (this.props.visSett.isTwOutCdisplayed) cond2.qwC = K_cond.zero;
      else cond2.twOutC = K_cond.zero;
      if (this.props.visSett.isTwOutHdisplayed) cond2.qwH = K_cond.zero;
      else cond2.twOutH = K_cond.zero;
      if (this.props.visSett.isTrivDisplayed) cond2.sabine = K_cond.zero;
      else cond2.tRiv = K_cond.zero;
      if (this.props.GUIsel.btnSel.brs) cond2.hz = K_cond.zero;
      else cond2.hz = cond2.labelHz;

      this.props.setCond(cond2);
      this.props.setVisSett(visSett2);

      if (this.props.config.isServer) {
        const maccCalc = await this.props.getResWeb("compute/calcMacc", {
          maccDaCalc: idsSelMacc,
          cond: this.props.cond,
          idU: this.props.config.idU,
        });

        this.props.setMaccCalc(maccCalc);
        this.props.changeView(false);
        this.props.toggleOverlay();
      } else {
        const { ipcRenderer } = window.require("electron");

        // qui devo usare once, e non on, sennò la seconda volta che faccio calc il toggleOverlay rimane sempre attivo
        ipcRenderer.once("calcMaccResp", (event, maccCalc) => {
          this.props.setMaccCalc(maccCalc);
          this.props.changeView(false);
          this.props.toggleOverlay();
        });

        ipcRenderer.send("calcMacc", {
          maccDaCalc: idsSelMacc,
          cond: this.props.cond,
          idU: this.props.config.idU,
        });
      }
    })();

    return;
  }

  render() {
    return (
      <>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <Btn
            value={this.props.t("compute").toUpperCase()}
            onClick={() => this._btnClick()}
            extraClassName="btn_width_l btn_height_l font_xl isDarkGray"
          />
        </div>
      </>
    );
  }
}

export default withNamespaces()(ComputeBtn);
