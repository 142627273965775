import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import PopUp from "../_components/PopUp";
import MaccSel from "./MaccSel";
import Cond from "./Cond";
import ComputeBtn from "./ComputeBtn";
import "./main.css";

class Main extends Component {
  constructor(props) {
    super(props);

    this.popup = React.createRef();
  }

  componentDidMount() {
    document.body.className = "body-main";
  }

  render() {
    return (
      <div id="wrapper-main">
        <PopUp
          isVisible={this.props.visSett.popUpIsVis}
          labelPopUpSms={this.props.visSett.labelPopUpSms}
        />
        <div id="home" className="main">
          <div className="container-fluid">
            <div id="spazia-main" />
            <div className="row row1">
              <MaccSel
                cond={this.props.cond}
                GUIsel={this.props.GUIsel}
                usrSett={this.props.usrSett}
                displayPopUpSms={m => this.props.displayPopUpSms(m)}
                setCond={c => this.props.setCond(c)}
                updateCatalog={m => this.props.updateCatalog(m)}
              />
            </div>
            <div className="row row2">
              <Cond
                cond={this.props.cond}
                GUIsel={this.props.GUIsel}
                validator={this.props.validator}
                usrSett={this.props.usrSett}
                visSett={this.props.visSett}
                displayPopUpSms={m => this.props.displayPopUpSms(m)}
                setCond={c => this.props.setCond(c)}
                setVisSett={v => this.props.setVisSett(v)}
                updateCatalog={m => this.props.updateCatalog(m)}
              />
            </div>
            <div className="row row3">
              <ComputeBtn
                cond={this.props.cond}
                config={this.props.config}
                catalog={this.props.catalog}
                GUIsel={this.props.GUIsel}
                usrSett={this.props.usrSett}
                validator={this.props.validator}
                visSett={this.props.visSett}
                displayPopUpSms={m => this.props.displayPopUpSms(m)}
                changeView={v => this.props.changeView(v)}
                getResWeb={async (u, d) => this.props.getResWeb(u, d)}
                setCond={c => this.props.setCond(c)}
                setMaccCalc={m => this.props.setMaccCalc(m)}
                setVisSett={r => this.props.setVisSett(r)}
                toggleOverlay={() => this.props.toggleOverlay()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Main);
