import React from "react";
import { withNamespaces } from "react-i18next";
import "./lang.css";

class Lang extends React.Component {
  render() {
    return (
      <>
        <div className="languages-div">
          <select
            id="languages"
            key="languages"
            value={this.props.usrSett.lang}
            disabled={this.props.isDisabled}
            onChange={(e) => this.props.setLang(e.target.value)}
          >
            <option value="en" key="langEn">
              {this.props.t("eng")}
            </option>
            <option value="it" key="langIt">
              {this.props.t("ita")}
            </option>
            <option value="fr" key="langFr">
              {this.props.t("fre")}
            </option>
            <option value="ge" key="langGe">
              {this.props.t("ger")}
            </option>
            <option value="po" key="langPo">
              {this.props.t("pol")}
            </option>
          </select>
        </div>
      </>
    );
  }
}

export default withNamespaces()(Lang);
