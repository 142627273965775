import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
const K_frontend = require("../assets/support/__k_frontend"),
  frasi = require("../assets/support/__frasi");

i18n.use(reactI18nextModule).init({
  fallbackLng: K_frontend.dflt_lang,
  ns: ["translations"],
  defaultNS: "translations",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: { translations: frasi.en },
    it: { translations: frasi.it },
    fr: { translations: frasi.fr },
    ge: { translations: frasi.ge },
    po: { translations: frasi.po },
  },
});

export default i18n;
