const K_cond = require("../assets/support/__k_cond"),
  K_min_max_dec = require("../assets/support/__k_min_max_dec");

function _calcPresAtm(altitu) {
  return (
    K_cond.dflt_presAtm *
    Math.pow(1 - K_cond.k1_altitu * parseFloat(altitu), K_cond.k2_altitu)
  );
}

function _calcUmidSpec(temp, rh, presAtm) {
  let presVap_sat_ta = _calcPresSat(temp),
    presVap = presVap_sat_ta * (rh / 100);

  return K_cond.k_rhSpec * (presVap / (presAtm * 1000 - presVap));
}

function _calcWetBulb(temp, pres, umidRatio) {
  let twb = temp,
    count = 1,
    error = 1.0;

  while (count < 10000 && error > 0.001) {
    let presParz_VapSat_bu = _calcPresSat(twb),
      umidRatio_bu = _calcUmidRatio(presParz_VapSat_bu, pres),
      test =
        (K_cond.k1_eq35 * (umidRatio_bu - umidRatio) -
          temp * (K_cond.k3_eq35 + K_cond.k4_eq35 * umidRatio)) /
        (K_cond.k2_eq35 * umidRatio_bu -
          K_cond.k3_eq35 -
          K_cond.specHeatW * umidRatio);

    error = twb - test;
    twb = twb - error / 100;
    count = count + 1;
  }

  return twb;
}

function _calcGradoSat(umidRatio, umidRatioSat) {
  return umidRatio / umidRatioSat;
}

function _eq_35(twb, umidRatio, temp) {
  let w =
    ((K_cond.k1_eq35 - K_cond.k2_eq35 * twb) * umidRatio -
      K_cond.k3_eq35 * (temp - twb)) /
    (K_cond.k1_eq35 + K_cond.k4_eq35 * temp - K_cond.specHeatW * twb);

  return w;
}

function _calcUmidRatio(presParz_Vap_bu, pres) {
  return (K_cond.k_rhSpec * presParz_Vap_bu) / (pres - presParz_Vap_bu);
}

function _calcUmidRel(gradoSat, pres_VapSat_bu, pres) {
  return 100 * (gradoSat / (1.0 - (1.0 - gradoSat) * (pres_VapSat_bu / pres)));
}

function _calcPresSat(temp) {
  let pres;

  if (temp > 0) {
    temp = temp + K_min_max_dec.celsius_kelvin;
    pres = Math.exp(
      K_cond.satPress_c08 / temp +
        K_cond.satPress_c09 +
        K_cond.satPress_c10 * temp +
        K_cond.satPress_c11 * temp * temp +
        K_cond.satPress_c12 * temp * temp * temp +
        K_cond.satPress_c13 * Math.log(temp)
    );
  } else {
    temp = temp + K_min_max_dec.celsius_kelvin;
    pres = Math.exp(
      K_cond.satPress_c01 / temp +
        K_cond.satPress_c02 +
        K_cond.satPress_c03 * temp +
        K_cond.satPress_c04 * temp * temp +
        K_cond.satPress_c05 * temp * temp * temp +
        K_cond.satPress_c06 * temp * temp * temp * temp +
        K_cond.satPress_c07 * Math.log(temp)
    );
  }

  return pres;
}

function calcMiscC(
  aExtC_par,
  tIntC_par,
  tExtC_par,
  urIntC_par,
  urExtC_par,
  altitu
) {
  let aExtC = parseFloat(aExtC_par),
    tIntC = parseFloat(tIntC_par),
    tExtC = parseFloat(tExtC_par),
    urIntC = parseFloat(urIntC_par),
    urExtC = parseFloat(urExtC_par),
    presAtm = _calcPresAtm(altitu),
    umidSpecInt = _calcUmidSpec(tIntC, urIntC, presAtm),
    umidSpecExt = _calcUmidSpec(tExtC, urExtC, presAtm),
    umidSpecMix =
      ((100 - aExtC) / 100) * umidSpecInt + (aExtC / 100) * umidSpecExt,
    tbsC = ((100 - aExtC) / 100) * tIntC + (aExtC / 100) * tExtC,
    umidRel =
      (((umidSpecMix * presAtm * 1000) / (umidSpecMix + K_cond.k_rhSpec)) *
        100) /
      _calcPresSat(parseFloat(tbsC)),
    tbuC = calcTwbFromDb(tbsC, umidRel, altitu);

  return { taBa: tbsC, taBu: tbuC, rh: umidRel };
}

function calcMiscH(aExtH, tIntH, tExtH) {
  return (((100 - aExtH) * tIntH + aExtH * tExtH) / 100).toFixed(
    K_min_max_dec.decT
  );
}

function calcTwbFromDb(temp_par, ur_par, altitu) {
  let temp = parseFloat(temp_par),
    rh = parseFloat(ur_par),
    presAtm = _calcPresAtm(altitu),
    pres_VapSat_bu = _calcPresSat(temp),
    presParz_Vap_bu = (pres_VapSat_bu * rh) / 100.0,
    umidRatio = _calcUmidRatio(presParz_Vap_bu, presAtm * 1000),
    twb = parseFloat(
      _calcWetBulb(temp, presAtm * 1000, umidRatio).toFixed(
        K_min_max_dec.decT
      )
    );

  return twb.toFixed(K_min_max_dec.decT);
}

function calcRH(temp_par, tbu_par, altitu) {
  let temp = parseFloat(temp_par),
    twb = parseFloat(tbu_par),
    presAtm = _calcPresAtm(altitu),
    presParz_VapSat_bu = _calcPresSat(twb),
    umidRatio_bu = _calcUmidRatio(presParz_VapSat_bu, presAtm * 1000),
    umidRatio = _eq_35(twb, umidRatio_bu, temp),
    pres_VapSat_bu = _calcPresSat(temp),
    umidRatioSat = _calcUmidRatio(pres_VapSat_bu, presAtm * 1000),
    gradoSat = _calcGradoSat(umidRatio, umidRatioSat),
    rh = parseFloat(
      _calcUmidRel(gradoSat, pres_VapSat_bu, presAtm * 1000).toFixed(
        K_min_max_dec.decRh
      )
    );

  return rh.toFixed(K_min_max_dec.decRh);
}

function calcTwbFromWb(temp_par, ur_par, altitu) {
  let temp = parseFloat(temp_par),
    rh = parseFloat(ur_par),
    presAtm = _calcPresAtm(altitu),
    pres_VapSat_bu = _calcPresSat(temp),
    presParz_Vap_bu = (pres_VapSat_bu * rh) / 100.0,
    umidRatio_bu = _calcUmidRatio(presParz_Vap_bu, presAtm * 1000),
    twb = parseFloat(
      _calcWetBulb(temp, presAtm * 1000, umidRatio_bu).toFixed(
        K_min_max_dec.decT
      )
    );

  return twb.toFixed(K_min_max_dec.decT);
}

export {
  calcTwbFromDb,
  calcRH,
  calcTwbFromWb,
  calcMiscC,
  calcMiscH,
};
