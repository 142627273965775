import React from "react";
import { withNamespaces } from "react-i18next";
import _ from "lodash";
import ColBtns from "../../_components/ColBtns";
import Btn from "../../_components/Btn";
import ColSpeed from "./ColSpeed";
import "./maccSel.css";
const K_frontend = require("../../../assets/support/__k_frontend"),
  images = require.context("../../../assets/images/modelli", true);

class MaccSel extends React.Component {
  changeBtns(e) {
    let maccSelData2 = this.props.GUIsel;
    switch (e.target.name) {
      case "btnEst":
        maccSelData2.btnSel.brs = !maccSelData2.btnSel.brs;
        break;
      case "btnStd":
        maccSelData2.btnSel.std = !maccSelData2.btnSel.std;
        break;

      case "btnDC":
        maccSelData2.btnSel.std = !maccSelData2.btnSel.std;
        break;

      default:
        break;
    }

    this.props.updateCatalog(maccSelData2);
  }

  changeHertz() {
    let cond2 = this.props.cond;
    if (cond2.hz === 50) {
      cond2.hz = 60;
      cond2.labelHz = 60;
    } else {
      cond2.hz = 50;
      cond2.labelHz = 50;
    }
    this.props.setCond(cond2);
  }

  changeRng(i) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.range = new Array(
      maccSelData2.btnSel.range.length
    ).fill(false);
    maccSelData2.btnSel.range[i] = true;
    this.props.updateCatalog(maccSelData2);
  }

  changeVers(i) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.vers = new Array(maccSelData2.btnSel.vers.length).fill(
      false
    );
    maccSelData2.btnSel.vers[i] = true;
    this.props.updateCatalog(maccSelData2);
  }

  changeModel(i) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.model[i] = !maccSelData2.btnSel.model[i];
    if (maccSelData2.btnSel.model.indexOf(true) < 0)
      maccSelData2.btnSel.model[i] = !maccSelData2.btnSel.model[i];

    this.props.updateCatalog(maccSelData2);
  }

  changeBattP(i) {
    // TODO add check if it is possible to leave it without any selection
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.battP[i] = !maccSelData2.btnSel.battP[i];

    if (maccSelData2.btnSel.battP.indexOf(true) < 0)
      maccSelData2.btnSel.battP[i] = !maccSelData2.btnSel.battP[i];

    this.props.updateCatalog(maccSelData2);
  }

  changeBattA(i) {
    // Exit when the battery cannot be unclicked i.e. when the first element is one and != null
    let maccSelData2 = this.props.GUIsel;

    maccSelData2.btnSel.battA[i] = !maccSelData2.btnSel.battA[i];

    if (maccSelData2.btnSel.battA.indexOf(true) < 0)
      maccSelData2.btnSel.battA[0] = true;

    if (maccSelData2.disVal.battA[0] === 0) {
      if (_.lastIndexOf(maccSelData2.btnSel.battA, true) > 0)
        maccSelData2.btnSel.battA[0] = false;
    }

    this.props.updateCatalog(maccSelData2);
  }

  deSelectAllModel(e) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.model = new Array(
      maccSelData2.btnSel.model.length
    ).fill(false);
    this.props.updateCatalog(maccSelData2);
  }

  deSelectAllBattP(e) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.battP = new Array(
      maccSelData2.btnSel.battP.length
    ).fill(false);
    this.props.updateCatalog(maccSelData2);
  }

  selectAllModel(e) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.model = new Array(
      maccSelData2.btnSel.model.length
    ).fill(true);
    this.props.updateCatalog(maccSelData2);
  }

  selectAllBattP(e) {
    let maccSelData2 = this.props.GUIsel;
    maccSelData2.btnSel.battP = new Array(
      maccSelData2.btnSel.battP.length
    ).fill(true);
    this.props.updateCatalog(maccSelData2);
  }

  render() {
    return (
      <>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <div id="boxStdDc" className="cell text-center">
            <div className="center-btn-vertically">
              <Btn
                name="btnStd"
                value="Standard"
                isChecked={this.props.GUIsel.btnSel.std}
                extraClassName={"btn_height_l extra-margin-button"}
                onClick={(e) => this.changeBtns(e)}
              />
              <Btn
                isBlue={true}
                isChecked={!this.props.GUIsel.btnSel.std}
                extraClassName={"btn_height_l extra-margin-button lineHeight"}
                idTooltip="dc"
                data-for={this.idTooltip}
                dataTip={this.props.t("tip_DC")}
                name="btnDC"
                tipsEnabled={this.props.usrSett.tipsEnabled}
                value={"District\nCooling"}
                onClick={(e) => this.changeBtns(e)}
              />
            </div>
          </div>
          <div id="boxEstHz" className="cell text-center">
            <div className="center-btn-vertically">
              <Btn
                name="btnEst"
                value="EST"
                isChecked={this.props.GUIsel.btnSel.brs}
                isGreen={true}
                extraClassName={"btn_height_l extra-margin-btnEstHz btnEst"}
                dataTip={this.props.t("tip_btnEST")}
                tipsEnabled={this.props.usrSett.tipsEnabled}
                onClick={(e) => this.changeBtns(e)}
              />
              <div id="boxHz" className={"extra-margin-btnEstHz"}>
                <Btn
                  name="50Hz"
                  value="50 Hz"
                  onClick={() => this.changeHertz()}
                  isChecked={this.props.cond.labelHz === 50}
                  extraClassName="btn_width_xxs"
                />
                <Btn
                  name="60Hz"
                  value="60 Hz"
                  onClick={() => this.changeHertz()}
                  isChecked={this.props.cond.labelHz === 60}
                  extraClassName="btn_width_xxs"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="boxSerieVel"
          className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-xs-8"
        >
          <div className="cell">
            <div className="noMarg row">
              <ColBtns
                title={this.props.t("range").toUpperCase()}
                btnLbls={this.props.GUIsel.disVal.range}
                btnSel={this.props.GUIsel.btnSel.range}
                nCol={2}
                usrSett={this.props.usrSett}
                textLen={"10"}
                isSelAllEnabled={false}
                btn_width_s={true}
                onClick={(i) => this.changeRng(i)}
              />
              <ColBtns
                title={this.props.t("version").toUpperCase()}
                btnLbls={this.props.GUIsel.disVal.vers}
                btnSel={this.props.GUIsel.btnSel.vers}
                nCol={2}
                usrSett={this.props.usrSett}
                textLen={"10"}
                isSelAllEnabled={false}
                onClick={(i) => this.changeVers(i)}
              />
              <ColBtns
                title={this.props.t("model").toUpperCase()}
                btnLbls={this.props.GUIsel.disVal.model}
                btnSel={this.props.GUIsel.btnSel.model}
                nCol={2}
                usrSett={this.props.usrSett}
                textLen={"10"}
                isSelAllEnabled={true}
                deSelectAll={(e) => this.deSelectAllModel(e)}
                selectAll={(e) => this.selectAllModel(e)}
                btn_width_xxs={true}
                twoColumns={
                  this.props.GUIsel.disVal.model.length >
                  K_frontend.minNmodel2Col
                }
                onClick={(i) => this.changeModel(i)}
              />
              <ColBtns
                title={this.props.t("coil").toUpperCase()}
                btnLbls={this.props.GUIsel.disVal.battP}
                btnSel={this.props.GUIsel.btnSel.battP}
                nCol={2}
                usrSett={this.props.usrSett}
                textLen={"10"}
                isSelAllEnabled={true}
                selectAll={(e) => this.selectAllBattP(e)}
                deSelectAll={(e) => this.deSelectAllBattP(e)}
                onClick={(i) => this.changeBattP(i)}
              />
              <ColBtns
                title={this.props.t("auxCoil").toUpperCase()}
                btnLbls={this.props.GUIsel.disVal.battA}
                btnSel={this.props.GUIsel.btnSel.battA}
                nCol={2}
                usrSett={this.props.usrSett}
                textLen={"18"}
                isSelAllEnabled={false}
                onClick={(i) => this.changeBattA(i)}
              />
              <ColSpeed
                title={this.props.t("speed").toUpperCase()}
                btnLbls={this.props.GUIsel.disVal.etiVel}
                btnSel={this.props.GUIsel.btnSel.etiVel}
                brs={this.props.GUIsel.btnSel.brs}
                cond={this.props.cond}
                usrSett={this.props.usrSett}
                textLen={"10"}
                setCond={(c) => this.props.setCond(c)}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <div id="boxImg" className="cell">
            <img
              src={images("./" + this.props.GUIsel.disVal.imagine + ".jpg")}
              alt="Fan coil"
            />
          </div>
        </div>
      </>
    );
  }
}

export default withNamespaces()(MaccSel);
