import React, { Component } from "react";
import i18n from "../../tools/i18n.js";
import { withNamespaces } from "react-i18next";
import _ from "lodash";
import Login from "../Login";
import Main from "../Main";
import Results from "../Results";
import LateralMenu from "../LateralMenu";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from "react-loading-overlay";
import Catalog from "../../tools/catalog.js";
import Validator from "../../tools/validator.js";
import "./app.css";
import "./styles_var.css";
import "./font-awesome.min.css";
const configStart = require("../../assets/config"),
  K_cond = require("../../assets/support/__k_cond"),
  K_frontend = require("../../assets/support/__k_frontend"),
  K_backend = require("../../assets/support/__k_backend"),
  K_varie = require("../../assets/support/__k_varie");

class App extends Component {
  constructor(props) {
    super(props);

    this.popup = React.createRef();
    this.state = {
      catalog: new Catalog(),
      cond: {
        altitu: K_cond.dflt_altitu,
        roomHei: K_cond.dflt_roomHei,
        esp: K_cond.dflt_esp,
        fluidoC: K_cond.dflt_fluid,
        fluidoH: K_cond.dflt_fluid,
        hz: K_cond.dflt_hz,
        labelHz: K_cond.dflt_hz,
        roomWid: K_cond.dflt_roomWid,
        listaVel: K_cond.dflt_speedList,
        roomLen: K_cond.dflt_roomLen,
        micSorg: K_cond.dflt_micSrc,
        modoResa: K_cond.dflt_modoResa,
        qwC: K_cond.zero,
        qwH: K_cond.zero,
        rhInC: K_cond.std_rhInC,
        sabine: K_cond.zero,
        step: K_cond.stepMax,
        taInC: K_cond.std_taInC,
        taInH: K_cond.dflt_taInH,
        tRiv: K_cond.dflt_tRiv,
        taBuInC: K_cond.std_taBuInC,
        twInC: K_cond.std_twInC,
        twInH: K_cond.std_twInH,
        twOutC: K_cond.std_twOutC,
        twOutH: K_cond.std_twOutH,
      },
      config: configStart,
      visSett: {
        blinkC: K_frontend.blinkC,
        blinkFlowC: K_frontend.blinkFlowC,
        blinkH: K_frontend.blinkH,
        blinkRis: K_frontend.blinkRis,
        CMode: K_frontend.CMode,
        flowC: K_frontend.flowC,
        H4Pipes: K_frontend.H4Pipes,
        HMode: K_frontend.HMode,
        isCModePossible: K_frontend.isCModePossible,
        isEspEnabled: K_frontend.isEspEnabled,
        isFlowCModePossible: K_frontend.isFlowCModePossible,
        isHModePossible: K_frontend.isHModePossible,
        isLoading: K_frontend.isLoading,
        isMainView: K_frontend.isMainView,
        isQwHenabled: K_frontend.isQwHenabled,
        isToutHenabled: K_frontend.isToutHenabled,
        isTrivDisplayed: K_frontend.isTrivDisplayed,
        isTwOutCdisplayed: K_frontend.isTwOutCdisplayed,
        isTwOutHdisplayed: K_frontend.isTwOutHdisplayed,
        labelPopUpSms: K_varie.empty,
        popUpIsVis: K_frontend.popUpIsVis,
        presetList: K_frontend.presetList,
        resultsComputedOnce: K_frontend.resultsComputedOnce,
        selectedMac: K_frontend.selectedMac,
        selectedRow: K_frontend.selectedRow,
        selectedVel: K_frontend.selectedVel,
        sidebarOpen: K_frontend.sidebarOpen,
      },
      maccCalc: null,
      GUIsel: {
        disVal: null,
        btnSel: null,
      },
      usrSett: {
        lang: K_frontend.dflt_lang,
        showFceer: K_frontend.showFceer,
        showNr: K_frontend.showNr,
        showRe: K_frontend.showRe,
        showSpectrum: K_frontend.showSpectrum,
        tipsEnabled: K_frontend.tipsEnabled,
      },
      validator: new Validator(),
    };
    this.state.GUIsel.btnSel = this.state.catalog.getInitialBtnChecked();
    this.state.GUIsel.disVal = this.state.catalog.getInitialDisVal();
    this.onSetOpen = this.onSetOpen.bind(this);
  }

  componentDidMount() {
    this.setState({
      GUIsel: this.state.catalog.updateSel(this.state.GUIsel)
    });

    this._setPresetList();

    // caso desktop, per non doversi loggare ogni volta
    if (!this.state.config.isServer) {
      const { ipcRenderer } = window.require("electron");

      ipcRenderer.on("loggedChecked", (event, arg) => {
        let config2 = this.state.config;
        config2.logged = arg.logged;
        config2.idU = arg.idU;

        this.setConfig(config2);
      });
      ipcRenderer.send("checkLogged", {});
    }
  }

  _setCapMode(modoResa2) {
    let visSett2 = this.state.visSett;

    switch (modoResa2) {
      case "none":
        visSett2.CMode = false;
        visSett2.HMode = false;
        visSett2.H4Pipes = false;
        visSett2.isHModePossible =
          _.intersection(this.state.GUIsel.disVal.modiResa, [1, 4, 2]).length >
          0
            ? true
            : false;
        visSett2.isCModePossible =
          _.intersection(this.state.GUIsel.disVal.modiResa, [1, 0, 2, 3])
            .length > 0
            ? true
            : false;
        visSett2.flowC = false;
        visSett2.isFlowCModePossible = false;
        visSett2.isQwHenabled = true;
        visSett2.isToutHenabled = true;
        break;
      case K_backend.modoResaC:
        visSett2.CMode = true;
        visSett2.HMode = false;
        visSett2.H4Pipes = false;
        visSett2.isCModePossible = true;
        visSett2.flowC = false;
        visSett2.isHModePossible =
          _.intersection(this.state.GUIsel.disVal.modiResa, [1, 4, 2]).length >
          0
            ? true
            : false;
        visSett2.isFlowCModePossible = false;
        visSett2.isEspEnabled = this.state.GUIsel.btnSel.ducted ? true : false;
        visSett2.isQwHenabled = true;
        visSett2.isToutHenabled = true;
        break;
      case K_backend.modoResaH2p:
        visSett2.CMode = false;
        visSett2.HMode = true;
        visSett2.H4Pipes = false;
        visSett2.isHModePossible = true;
        visSett2.flowC = false;
        visSett2.isCModePossible =
          _.intersection(this.state.GUIsel.disVal.modiResa, [1, 0, 2, 3])
            .length > 0
            ? true
            : false;
        visSett2.isFlowCModePossible = true;
        visSett2.isEspEnabled = this.state.GUIsel.btnSel.ducted ? true : false;
        visSett2.isQwHenabled = true;
        visSett2.isToutHenabled = true;
        break;
      case K_backend.modoResaH4p:
        visSett2.CMode = false;
        visSett2.HMode = false;
        visSett2.H4Pipes = true;
        visSett2.isHModePossible = true;
        visSett2.flowC = false;
        visSett2.isCModePossible =
          _.intersection(this.state.GUIsel.disVal.modiResa, [1, 0, 2, 3])
            .length > 0
            ? true
            : false;
        visSett2.isFlowCModePossible = false;
        visSett2.isEspEnabled = this.state.GUIsel.btnSel.ducted ? true : false;
        visSett2.isQwHenabled = true;
        visSett2.isToutHenabled = true;
        break;
      case K_backend.modoResaCH2p:
        visSett2.CMode = true;
        visSett2.HMode = true;
        visSett2.H4Pipes = false;
        visSett2.isHModePossible = true;
        visSett2.isCModePossible = true;
        visSett2.flowC = false;
        visSett2.isFlowCModePossible = true;
        visSett2.isEspEnabled = this.state.GUIsel.btnSel.ducted ? true : false;
        visSett2.isQwHenabled = true;
        visSett2.isToutHenabled = true;
        break;
      case K_backend.modoResaCH2pFlowC:
        visSett2.CMode = true;
        visSett2.HMode = true;
        visSett2.H4Pipes = false;
        visSett2.isHModePossible = true;
        visSett2.isCModePossible = true;
        visSett2.flowC = true;
        visSett2.isFlowCModePossible = true;
        visSett2.isQwHenabled = false;
        visSett2.isToutHenabled = false;

        break;
      case K_backend.modoResaCH4p:
        visSett2.CMode = true;
        visSett2.HMode = false;
        visSett2.H4Pipes = true;
        visSett2.flowC = false;
        visSett2.isHModePossible = true;
        visSett2.isCModePossible = true;
        visSett2.isFlowCModePossible = false;
        visSett2.isEspEnabled = this.state.GUIsel.btnSel.ducted ? true : false;
        visSett2.isQwHenabled = true;
        visSett2.isToutHenabled = true;
        break;
      default:
        break;
    }

    this.setVisSett(visSett2);
  }

  _setPresetList() {
    let labelList = ["labelNone"],
      visSett2 = this.state.visSett;

    const { modiResa } = this.state.GUIsel.disVal;

    if (
      _.intersection(modiResa, [0, 1, 3]).length > 0 &&
      this.state.GUIsel.btnSel.std
    )
      ["labelC", "labelCNS1", "labelCNS2", "labelCNS3"].forEach((el) =>
        labelList.push(el)
      );
    if (!this.state.GUIsel.btnSel.std)
      ["labelDC", "labelDCns1"].forEach((el) => labelList.push(el));
    if (
      _.intersection(modiResa, [5, 3]).length > 0 &&
      this.state.GUIsel.btnSel.std
    )
      ["labelH4Pipes", "labelH4PipesNS1"].forEach((el) => labelList.push(el));
    if (
      _.intersection(modiResa, [4, 1]).length > 0 &&
      this.state.GUIsel.btnSel.std
    )
      ["labelH2Pipes", "labelH2PipesNS1"].forEach((el) => labelList.push(el));

    visSett2.presetList = labelList;
    this.setVisSett(visSett2);
  }

  displayPopUpSms(lblMessage) {
    let visSett2 = this.state.visSett;
    visSett2.popUpIsVis = true;
    visSett2.labelPopUpSms = lblMessage;
    this.setVisSett(visSett2);

    setTimeout(() => {
      visSett2.popUpIsVis = false;
      this.setVisSett(visSett2);
    }, 5000);
  }

  async getResWeb(apiUrl, data) {
    const resp = await fetch(this.state.config.urlServer + apiUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    return await resp.json();
  }

  changeView(value) {
    let visSett2 = this.state.visSett;
    visSett2.isMainView = value;
    this.setVisSett(visSett2);
  }

  onSetOpen(open) {
    let visSett2 = this.state.visSett;
    visSett2.sidebarOpen = open;
    this.setVisSett(visSett2);
  }

  setCond(cond2) {
    if (!this.state.GUIsel.btnSel.ducted) cond2.esp = 0;
    this.setState({ cond: cond2 });
    this._setCapMode(cond2.modoResa);
  }

  setLang(lang) {
    let usrSett2 = this.state.usrSett;
    usrSett2.lang = lang;

    this.setUsrSett(usrSett2);
    i18n.changeLanguage(lang);
  }

  setConfig(config2) {
    this.setState({ config: config2 });
  }

  setMaccCalc(maccCalc) {
    this.setState({ maccCalc: maccCalc });
  }

  setUsrSett(usrSett2) {
    this.setState({ usrSett: usrSett2 });
  }

  setVisSett(visSett2) {
    this.setState({ visSett: visSett2 });
  }

  toggleOverlay() {
    let visSett2 = this.state.visSett;
    visSett2.isLoading = !this.state.visSett.isLoading;
    this.setVisSett(visSett2);
  }

  updateCatalog(maccSelData2) {
    this.setState({
      GUIsel: this.state.catalog.updateSel(maccSelData2),
    });
    let modiResa = this.state.GUIsel.disVal.modiResa,
      cond2 = this.state.cond;
    cond2.modoResa = modiResa[0];

    // reset Velocita
    if (this.state.GUIsel.btnSel.brs)
      cond2.listaVel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    else {
      cond2.listaVel = [];

      this.state.GUIsel.btnSel.etiVel.forEach((el, idx) => {
        if (el === true) cond2.listaVel.push(idx + 1);
      });
    }

    this.setCond(cond2);
    this._setPresetList();
  }

  render() {
    let lateralMenu, mainWindow;

    // lateralMenu
    if (this.state.config.logged) {
      lateralMenu = (
        <>
          <LateralMenu
            cond={this.state.cond}
            config={this.state.config}
            maccCalc={this.state.maccCalc}
            usrSett={this.state.usrSett}
            visSett={this.state.visSett}
            getResWeb={async (u, d) => this.getResWeb(u, d)}
            changeView={(v) => this.changeView(v)}
            onSetOpen={(o) => this.onSetOpen(o)}
            setConfig={(c) => this.setConfig(c)}
            setLang={(c) => this.setLang(c)}
            setUsrSett={(u) => this.setUsrSett(u)}
            toggleOverlay={() => this.toggleOverlay()}
          />
        </>
      );
    }

    // loggato, main view
    if (this.state.visSett.isMainView && this.state.config.logged) {
      mainWindow = (
        <Main
          catalog={this.state.catalog}
          cond={this.state.cond}
          config={this.state.config}
          GUIsel={this.state.GUIsel}
          usrSett={this.state.usrSett}
          validator={this.state.validator}
          visSett={this.state.visSett}
          displayPopUpSms={(m) => this.displayPopUpSms(m)}
          getResWeb={async (u, d) => this.getResWeb(u, d)}
          changeView={(v) => this.changeView(v)}
          setCond={(c) => this.setCond(c)}
          setMaccCalc={(m) => this.setMaccCalc(m)}
          setVisSett={(v) => this.setVisSett(v)}
          toggleOverlay={() => this.toggleOverlay()}
          updateCatalog={(m) => this.updateCatalog(m)}
        />
      );
    }
    // loggato, results view
    else if (
      !this.state.visSett.isMainView &&
      this.state.config.logged &&
      this.state.visSett.resultsComputedOnce
    ) {
      mainWindow = (
        <div className="col-xl">
          <Results
            cond={this.state.cond}
            maccCalc={this.state.maccCalc}
            usrSett={this.state.usrSett}
            visSett={this.state.visSett}
            onSetOpen={(o) => this.onSetOpen(o)}
            setVisSett={(n) => this.setVisSett(n)}
          />
        </div>
      );
    }
    // non loggato
    else if (!this.state.config.logged) {
      mainWindow = (
        <Login
          config={this.state.config}
          usrSett={this.state.usrSett}
          visSett={this.state.visSett}
          displayPopUpSms={(m) => this.displayPopUpSms(m)}
          getResWeb={async (u, d) => this.getResWeb(u, d)}
          setConfig={(c) => this.setConfig(c)}
          setLang={(c) => this.setLang(c)}
          setUsrSett={(u) => this.setUsrSett(u)}
          toggleOverlay={() => this.toggleOverlay()}
        />
      );
    }

    return (
      <>
        <LoadingOverlay
          active={this.state.visSett.isLoading}
          spinner={<BeatLoader />}
          text={this.props.t("computing")}
        >
          <div id="App" className="noselect">
            {lateralMenu}
            {mainWindow}
          </div>
        </LoadingOverlay>
      </>
    );
  }
}

export default withNamespaces()(App);
