import "bootstrap/dist/css/bootstrap.css"; //deve stare per primo
import "./src.css";
import "./styles_colorAz.css";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "./tools/i18n.js";
import App from "./containers/App";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
