import React from "react";
import { withNamespaces } from "react-i18next";
import InputBox from "../../../../_components/InputBox";
import Btn from "../../../../_components/Btn";
import { calcMiscH } from "../../../../../tools/calc_thermodynamic.js";
const K_cond = require("../../../../../assets/support/__k_cond"),
  K_min_max_dec = require("../../../../../assets/support/__k_min_max_dec");

class TableHAirSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airMixVis: false,
      percentAirExtH: parseFloat(K_cond.dflt_percentAirExtH).toFixed(K_min_max_dec.decPercAir),
      taExtH: parseFloat(K_cond.dflt_taExtH).toFixed(K_min_max_dec.decT),
      taIntH: parseFloat(K_cond.dflt_taIntH).toFixed(K_min_max_dec.decT),
      taInH: parseFloat(K_cond.dflt_taInH).toFixed(K_min_max_dec.decT)
    };
  }

  componentDidMount() {
    this.props.cond.taInH = parseFloat(this.props.cond.taInH).toFixed(
      K_min_max_dec.decT
    );
  }

  onKeyPress(e) {
    let cond = this.props.cond;
    let percentAirExtH, taExtH, taIntH;

    if (e.key === "Enter") {
      if (!this.props.validator.isValid(e.target.name, e.target.value)) {
        if (
          !(
            e.target.value === undefined ||
            isNaN(e.target.value) ||
            e.target.value === ""
          )
        )
          this.props.displayPopUpSms(this.props.t("err_" + e.target.name));

        cond.taInH = this.props.validator.fallBackValue("taInH");
        percentAirExtH = this.props.validator.fallBackValue("percentAirExtH");
        taExtH = this.props.validator.fallBackValue("taExtH");
        taIntH = this.props.validator.fallBackValue("taIntH");

        this.setState({ taExtH: taExtH });
        this.setState({ taIntH: taIntH });
        this.setState({ percentAirExtH: percentAirExtH });
        this.props.setCond(cond);

        return;
      }

      switch (e.target.name) {
        case "taInH":
          cond.taInH = parseFloat(e.target.value).toFixed(K_min_max_dec.decT);
          this.props.setCond(cond);
          break;
        case "percentAirExtH":
          cond.taInH = parseFloat(
            calcMiscH(
              this.state.percentAirExtH,
              this.state.taIntH,
              this.state.taExtH
            )
          ).toFixed(K_min_max_dec.decT);
          this.props.setCond(cond);
          this.props.validator.setFallBackValue(
            "percentAirExtH",
            this.state.percentAirExtH
          );
          this.props.validator.setFallBackValue("taInH", this.props.cond.taInH);
          break;
        case "taExtH":
          cond.taInH = parseFloat(
            calcMiscH(
              this.state.percentAirExtH,
              this.state.taIntH,
              this.state.taExtH
            )
          ).toFixed(K_min_max_dec.decT);
          this.setState({
            taExtH: parseFloat(this.state.taExtH).toFixed(K_min_max_dec.decT)
          });
          this.props.setCond(cond);
          // set fallback values
          this.props.validator.setFallBackValue("taExtH", this.state.taExtH);
          this.props.validator.setFallBackValue("taInH", this.props.cond.taInH);
          break;
        case "taIntH":
          cond.taInH = parseFloat(
            calcMiscH(
              this.state.percentAirExtH,
              this.state.taIntH,
              this.state.taExtH
            )
          ).toFixed(K_min_max_dec.decT);
          this.setState({
            taIntH: parseFloat(this.state.taIntH).toFixed(K_min_max_dec.decT)
          });
          this.props.setCond(cond);
          // set fallback values
          this.props.validator.setFallBackValue("taIntH", this.state.taIntH);
          this.props.validator.setFallBackValue("taInH", this.props.cond.taInH);
          break;

        default:
          break;
      }
    }
  }

  onChange(e) {
    switch (e.target.name) {
      case "taInH":
        let cond = this.props.cond;
        cond.taInH = e.target.value;
        this.props.setCond(cond);
        break;
      case "percentAirExtH":
        this.setState({ percentAirExtH: e.target.value });
        break;
      case "taExtH":
        this.setState({ taExtH: e.target.value });
        break;
      case "taIntH":
        this.setState({ taIntH: e.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    let airMixTableBody, airMixTableTitle;
    if (this.state.airMixVis) {
      airMixTableTitle = (
        <>
          <td className="text-center">
            <span>{this.props.t("airInt")}</span>
          </td>
          <td className="text-center">
            <span>{this.props.t("airExt")}</span>
          </td>
        </>
      );
      airMixTableBody = (
        <>
          <tr>
            <td>{this.props.t("airExt") + "  [%]"}</td>
            <td />
            <td className="text-center">
              <InputBox
                type="text"
                className="text-center"
                name="percentAirExtH"
                value={this.state.percentAirExtH}
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("tdb_abbr") + "  [°C]"}</td>
            <td className="text-center">
              <InputBox
                name="taIntH"
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
                type="text"
                className="text-center"
                value={this.state.taIntH}
              />
            </td>
            <td className="text-center">
              <InputBox
                type="text"
                className="text-center"
                name="taExtH"
                value={this.state.taExtH}
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
              />
            </td>
          </tr>
        </>
      );
    }

    return (
      <table id="tableAirCald" className="table">
        <caption className="text-center table-caption">
          {this.props.title}
        </caption>
        <tbody>
          <tr>
            <td id="btnMixRi">
              <Btn
                value={this.props.t("airMix")}
                onClick={() =>
                  this.setState({
                    airMixVis: !this.state.airMixVis
                  })
                }
                isChecked={this.state.airMixVis}
                isRed={true}
                extraClassName="btn_width_xs"
              />
            </td>
            {airMixTableTitle}
          </tr>
          {airMixTableBody}
          <tr>
            <td>{this.props.t("tdb_abbr") + " [°C]"}</td>
            <td className="text-center" colSpan="2">
              <InputBox
                className={this.props.visSett.blinkH ? "blink" : ""}
                key="taInH"
                name="taInH"
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
                value={this.props.cond.taInH}
                type="text"
                isDisabled={this.state.airMixVis}
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withNamespaces()(TableHAirSide);
