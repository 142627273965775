import React from "react";
import { withNamespaces } from "react-i18next";
import _ from "lodash";
import Lang from "../_components/Lang";
import Sidebar from "react-sidebar";
import Btn from "../_components/Btn";
import "./lateralMenu.css";
const K_varie = require("../../assets/support/__k_varie");

class LateralMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logoSW: null,
      logoAzienda: null,
    };
  }

  componentDidMount() {
    document.body.className = "body-login";

    let logoSWimg, logoAziendaImg;

    if (this.props.config.isEura) {
      logoSWimg = require("../../assets/images/logoGeniusplanTrasparente.png");
      logoAziendaImg = require("../../assets/images/logoEurapoTrasparente.png");
    } else {
      logoSWimg = require("../../assets/images/logoLaserTrasparente.png");
      logoAziendaImg = require("../../assets/images/logoYorkTrasparente.png");
    }

    this.setState({ logoSW: logoSWimg, logoAzienda: logoAziendaImg });

    // caso desktop, per non doversi loggare ogni volta
    if (!this.props.config.isServer) {
      const { ipcRenderer } = window.require("electron");

      ipcRenderer.once("loggedChecked", (event, arg) => {
        let config2 = this.props.config;
        config2.logged = arg.logged;
        config2.idU = arg.idU;

        this.props.setConfig(config2);
      });

      ipcRenderer.send("checkLogged", {});
    }
  }

  _exp(req, data, risp) {
    (async () => {
      if (this.props.config.isServer) {
        this.props.toggleOverlay();

        let window2 = window.open(
          this.props.config.urlServer + "wait",
          "_blank"
        );

        const resp = await this.props.getResWeb("download/" + req, data),
          response = {
            file:
              this.props.config.urlServer +
              "download/" +
              risp +
              "/?filename=" +
              encodeURIComponent(resp),
          };

        setTimeout(() => {
          window2.location = response.file;
          setTimeout(() => {
            window2.close();
          }, 1000);
        }, 1000);

        this.props.toggleOverlay();
      } else {
        await this._getResDesk(req, risp, data);
      }
    })();
  }

  _expDocx() {
    let data = {
        macCalc: this.props.maccCalc[this.props.visSett.selectedMac],
        indVel: this.props.visSett.selectedVel,
        cond: this.props.cond,
        usrSett: this.props.usrSett,
        idU: this.props.config.idU,
      },
      risp = this.props.config.isServer ? "wordGenerated" : "docx";

    this._exp("word", data, risp);
  }

  _expHelp() {
    let risp = this.props.config.isServer ? "helpGenerated" : "pdf";

    this._exp("help", { usrSett: this.props.usrSett }, risp);
  }

  _expPdf() {
    let risp = this.props.config.isServer ? "pdfGenerated" : "pdf";

    this._exp(
      "pdf",
      {
        macCalc: this.props.maccCalc[this.props.visSett.selectedMac],
        indVel: this.props.visSett.selectedVel,
        cond: this.props.cond,
        usrSett: this.props.usrSett,
        idU: this.props.config.idU,
      },
      risp
    );
  }

  _expXlsx() {
    let risp = this.props.config.isServer ? "excelGenerated" : "xlsx";

    this._exp(
      "excel",
      {
        maccCalc: this.props.maccCalc,
        cond: this.props.cond,
        usrSett: this.props.usrSett,
        idU: this.props.config.idU,
      },
      risp
    );
  }

  async _getResDesk(dialogName, ext, data) {
    const { ipcRenderer } = window.require("electron"),
      { remote } = window.require("electron"),
      dialog = remote.dialog,
      win = remote.getCurrentWindow(),
      respName = dialogName + "Resp";
    let nomeFile,
      dataTosend = _.clone(data),
      fileToSave;

    if (dialogName === "help") nomeFile = "help";
    else if (dialogName === "excel") nomeFile = this.props.t("selection");
    else nomeFile = data.macCalc.nome.replace("/", "");

    this.props.toggleOverlay();

    let dialogo = await dialog.showSaveDialog(win, {
      title: dialogName,
      defaultPath: nomeFile,
      filters: [
        { name: dialogName, extensions: [ext] },
        { name: "All Files", extensions: ["*"] },
      ],
    });

    fileToSave = dialogo.filePath;

    if (
      fileToSave === undefined ||
      (!this.props.config.isDev && fileToSave === "")
    ) {
      alert(this.props.t("err_notSaved"));
      this.props.toggleOverlay();

      return;
    } else {
      dataTosend = _.assign(dataTosend, { filename: fileToSave });

      // qui devo usare once, e non on, sennò la seconda volta che salvo il file il toggleOverlay rimane sempre attivo
      ipcRenderer.once(respName, (event) => {
        this.props.toggleOverlay();
      });

      ipcRenderer.send(dialogName, dataTosend);
      this.props.toggleOverlay();
    }
  }

  _logout() {
    let config2 = this.props.config;
    config2.logged = false;

    this.props.setConfig(config2);
    window.location.reload("https://www.geniusplan.it");
  }

  _setShowFceer() {
    let usrSett2 = this.props.usrSett;
    usrSett2.showFceer = !this.props.usrSett.showFceer;
    this.props.setUsrSett(usrSett2);
  }

  _setShowNr() {
    let usrSett2 = this.props.usrSett;
    usrSett2.showNr = !this.props.usrSett.showNr;
    this.props.setUsrSett(usrSett2);
  }

  _setShowRe() {
    let usrSett2 = this.props.usrSett;
    usrSett2.showRe = !this.props.usrSett.showRe;
    this.props.setUsrSett(usrSett2);
  }

  _setShowSpectrum() {
    let usrSett2 = this.props.usrSett;
    usrSett2.showSpectrum = !this.props.usrSett.showSpectrum;
    this.props.setUsrSett(usrSett2);
  }

  _toggleTooltips() {
    let usrSett2 = this.props.usrSett;
    usrSett2.tipsEnabled = !this.props.usrSett.tipsEnabled;
    this.props.setUsrSett(usrSett2);
  }

  render() {
    let homeResultsBtns, resultsOptBtns, expBtns, logoutBtn, desktopBtn;

    // home & results btns, results options btns, excel btn
    homeResultsBtns = (
      <React.Fragment key="resultsBtns">
        <Btn
          extraClassName="btn_width_xxl btn_height_m font_l"
          isChecked={this.props.visSett.isMainView}
          isDisabled={this.props.visSett.isLoading}
          isDarkGray={true}
          value="Home"
          onClick={() => this.props.changeView(true)}
        />
        <Btn
          extraClassName="btn_width_xxl btn_height_m font_l"
          isChecked={!this.props.visSett.isMainView}
          isDarkGray={true}
          isDisabled={
            !this.props.visSett.resultsComputedOnce ||
            this.props.visSett.isLoading
          }
          value={this.props.t("results")}
          onClick={() => this.props.changeView(false)}
        />
      </React.Fragment>
    );
    resultsOptBtns = (
      <React.Fragment key="resultsOptBtns">
        <div className="btn_width_xxl text-center">
          <div className="cell-title btn_height_s">
            <h4>{this.props.t("opResults").toUpperCase()}</h4>
          </div>
          <Btn
            value="N° REYNOLDS"
            onClick={() => this._setShowRe()}
            isChecked={this.props.usrSett.showRe}
            isDisabled={this.props.visSett.isLoading}
            extraClassName="btn_width_xl btn_height_s font_m"
          />
          <Btn
            value="FCEER / FCCOP"
            onClick={() => this._setShowFceer()}
            isChecked={this.props.usrSett.showFceer}
            isDisabled={this.props.visSett.isLoading}
            extraClassName="btn_width_xl btn_height_s font_m"
          />
          <Btn
            value="NR / NC"
            onClick={() => this._setShowNr()}
            isChecked={this.props.usrSett.showNr}
            isDisabled={this.props.visSett.isLoading}
            extraClassName="btn_width_xl btn_height_s font_m"
          />
          <Btn
            extraClassName="btn_width_xl btn_height_l font_m"
            isChecked={this.props.usrSett.showSpectrum}
            isDisabled={this.props.visSett.isLoading}
            value={this.props.t("spectrumLw").toUpperCase()}
            onClick={() => this._setShowSpectrum()}
          />
        </div>
      </React.Fragment>
    );

    // export btns
    if (!this.props.visSett.isMainView) {
      expBtns = (
        <React.Fragment key="exp">
          <div className="lateralMenu-division" />
          <div className="btn_width_xxl text-center">
            <div className="cell-title btn_height_s">
              <h4>{this.props.t("export").toUpperCase()}</h4>
            </div>
            <span>
              <Btn
                extraClassName="btn_width_xl btn_height_s font_m"
                isDisabled={this.props.visSett.isLoading}
                value="EXCEL"
                onClick={() => this._expXlsx()}
              />
            </span>
            <span>
              <Btn
                idTooltip="word"
                data-for={this.idTooltip}
                dataTip={this.props.t("tip_selectRow")}
                extraClassName="btn_width_xl btn_height_s font_m"
                isDisabled={this.props.visSett.isLoading}
                tipsEnabled={this.props.usrSett.tipsEnabled}
                key="word"
                name="word"
                value="WORD"
                onClick={() => this._expDocx()}
              />
            </span>
            <span>
              <Btn
                idTooltip="pdf"
                data-for={this.idTooltip}
                dataTip={this.props.t("tip_selectRow")}
                extraClassName="btn_width_xl btn_height_s font_m"
                isDisabled={this.props.visSett.isLoading}
                tipsEnabled={this.props.usrSett.tipsEnabled}
                key="pdf"
                name="pdf"
                value="PDF"
                onClick={() => this._expPdf()}
              />
            </span>
          </div>
        </React.Fragment>
      );
    }

    // logout btn
    if (this.props.config.isServer) {
      logoutBtn = (
        <React.Fragment key="logout">
          <Btn
            extraClassName="btn_width_xl btn_height_s font_m"
            isDisabled={this.props.visSett.isLoading}
            value={this.props.t("logout").toUpperCase()}
            onClick={() => this._logout()}
          />
        </React.Fragment>
      );
      desktopBtn = (
        <React.Fragment key="desktop">
          <Btn
            idTooltip="desktop"
            data-for={this.idTooltip}
            dataTip={this.props.t("tip_desktop")}
            name="desktop"
            key="desktop"
            value={"DESKTOP"}
            onClick={() =>
              this._exp(
                "desktop",
                { usrSett: this.props.usrSett },
                "desktopGenerated"
              )
            }
            isDisabled={this.props.visSett.isLoading}
            tipsEnabled={this.props.usrSett.tipsEnabled}
            extraClassName="btn_width_xl btn_height_s font_m"
          />
        </React.Fragment>
      );
    }

    let classCellSw = "cell-title logo-div",
      classCellAz = "cell-title logo-div marginDivTop";
    classCellSw += this.props.config.isEura ? " logo-divGeni" : " logo-divLase";
    classCellAz += this.props.config.isEura ? " logo-divEura" : " logo-divYork";

    let sidebarContent = (
      <nav className="sidebar-main">
        <div className="space-for-logo">
          <div className={classCellSw}>
            <img src={this.state.logoSW} alt="LogoSW" />
          </div>
          <div id="vers" className="text-center">
            {this.props.t("version") + " " + K_varie.swVers}
          </div>
        </div>
        <div className="col-xl-1">
          {homeResultsBtns}
          <div key="div0" className="lateralMenu-division" />
          {resultsOptBtns}
          {expBtns}
        </div>
        <div id="logo-divAz" className="space-for-logo">
          <div className="btn_width_xxl text-center">
            {desktopBtn}
            <Btn
              key="tooltips"
              value={this.props.t("tooltips").toUpperCase()}
              isChecked={this.props.usrSett.tipsEnabled}
              isDisabled={this.props.visSett.isLoading}
              extraClassName="btn_width_xl btn_height_s font_m"
              onClick={() => this._toggleTooltips()}
            />
            <Btn
              key="help"
              value={this.props.t("help").toUpperCase()}
              onClick={() => this._expHelp()}
              isDisabled={this.props.visSett.isLoading}
              extraClassName="btn_width_xl btn_height_s font_m"
            />
            <Lang
              isDisabled={this.props.visSett.isLoading}
              usrSett={this.props.usrSett}
              setLang={(c) => this.props.setLang(c)}
              setUsrSett={(u) => this.props.setUsrSett(u)}
            />
            {logoutBtn}
          </div>
          <div className={classCellAz}>
            <img src={this.state.logoAzienda} alt="LogoAz" />
          </div>
        </div>
      </nav>
    );

    return (
      <>
        <Sidebar
          className="navbar"
          sidebar={sidebarContent}
          open={this.props.visSett.sidebarOpen}
          docked={this.props.visSett.isMainView}
          onSetOpen={this.props.onSetOpen}
          styles={{
            overlay: { backgroundColor: "rgba(0,0,0,0.4)" },
            sidebar: {
              background: "grey",
              position: "fixed",
              zIndex: 20000,
              top: 0,
              left: 0,
              width: 200,
              overflow: "hidden",
            },
          }}
        >
          <div />
        </Sidebar>
      </>
    );
  }
}

export default withNamespaces()(LateralMenu);
