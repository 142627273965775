import React from "react";
import { withNamespaces } from "react-i18next";
import TableCAirSide from "./TableCAirSide";
import TableCFluidSide from "./TableCFluidSide";
import TableHAirSide from "./TableHAirSide";
import TableHFluidSide from "./TableHFluidSide";
import "./condCenter.css";

class CondCenter extends React.Component {
  _calcClassNameC() {
    let className = "cell";

    if (!this.props.visSett.CMode) className += " disabled_mc";

    return className;
  }

  _calcClassNameH() {
    let className = "cell";

    if (!this.props.visSett.HMode && !this.props.visSett.H4Pipes)
      className += " disabled_mc";

    return className;
  }

  render() {
    return (
      <>
        <div className={this._calcClassNameC()}>
          <div className="noMarg">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
              <div id="titleFred" className="cell-title">
                <h4>{this.props.t("cooling").toUpperCase()}</h4>
              </div>
            </div>
            <div className="two-table-align">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 zeroPad align-on-top">
                <TableCAirSide
                  cond={this.props.cond}
                  title={this.props.t("airSide").toUpperCase()}
                  usrSett={this.props.usrSett}
                  validator={this.props.validator}
                  visSett={this.props.visSett}
                  displayPopUpSms={m => this.props.displayPopUpSms(m)}
                  setCond={c => this.props.setCond(c)}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 zeroPad align-on-top">
                <TableCFluidSide
                  cond={this.props.cond}
                  title={this.props.t("fluidSide").toUpperCase()}
                  validator={this.props.validator}
                  visSett={this.props.visSett}
                  displayPopUpSms={m => this.props.displayPopUpSms(m)}
                  setCond={c => this.props.setCond(c)}
                  setVisSett={c => this.props.setVisSett(c)}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="rcCald" className={this._calcClassNameH()}>
          <div className="noMarg">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div id="titleCald" className="cell-title">
                <h4>
                  {(
                    this.props.t("heating") +
                    " " +
                    (this.props.visSett.H4Pipes
                      ? this.props.t("pipes4")
                      : this.props.t("pipes2"))
                  ).toUpperCase()}
                </h4>
              </div>
            </div>
            <div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 zeroPad align-on-top">
                <TableHAirSide
                  validator={this.props.validator}
                  cond={this.props.cond}
                  title={this.props.t("airSide").toUpperCase()}
                  usrSett={this.props.usrSett}
                  visSett={this.props.visSett}
                  displayPopUpSms={m => this.props.displayPopUpSms(m)}
                  setCond={c => this.props.setCond(c)}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 zeroPad align-on-top">
                <TableHFluidSide
                  cond={this.props.cond}
                  GUIsel={this.props.GUIsel}
                  title={this.props.t("fluidSide").toUpperCase()}
                  usrSett={this.props.usrSett}
                  validator={this.props.validator}
                  visSett={this.props.visSett}
                  blinkGroup={b => this.props.blinkGroup(b)}
                  displayPopUpSms={m => this.props.displayPopUpSms(m)}
                  setCond={c => this.props.setCond(c)}
                  setVisSett={v => this.props.setVisSett(v)}
                  updateCatalog={m => this.props.updateCatalog(m)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withNamespaces()(CondCenter);
