const K_cond = require("../assets/support/__k_cond"),
  K_min_max_dec = require("../assets/support/__k_min_max_dec");
class Validator {
  constructor() {
    this.fallBackVal = {
      roomLen: parseFloat(K_cond.dflt_roomLen).toFixed(K_min_max_dec.decMt),
      roomWid: parseFloat(K_cond.dflt_roomWid).toFixed(K_min_max_dec.decMt),
      roomHei: parseFloat(K_cond.dflt_roomHei).toFixed(K_min_max_dec.decMt),
      tRiv: parseFloat(K_cond.dflt_tRiv).toFixed(K_min_max_dec.decTrev),
      sabine: parseFloat(K_cond.dflt_sabine).toFixed(K_min_max_dec.decSab),
      micSorg: parseFloat(K_cond.dflt_micSrc).toFixed(
        K_min_max_dec.decMicSrc
      ),
      altitu: parseFloat(K_cond.dflt_altitu).toFixed(K_min_max_dec.decAltitu),
      esp: parseFloat(K_cond.dflt_esp).toFixed(K_min_max_dec.decEsp),
      // C
      taInC: parseFloat(K_cond.std_taInC).toFixed(K_min_max_dec.decT),
      rhInC: parseFloat(K_cond.std_rhInC).toFixed(K_min_max_dec.decRh),
      taBuInC: parseFloat(K_cond.std_taBuInC).toFixed(K_min_max_dec.decT),
      percentAirExtC: parseFloat(K_cond.dflt_percentAirExtC).toFixed(
        K_min_max_dec.decPercAir
      ),
      rhIntC: parseFloat(K_cond.dflt_rhIntC).toFixed(K_min_max_dec.decRh),
      taIntC: parseFloat(K_cond.dflt_taIntC).toFixed(K_min_max_dec.decT),
      rhExtC: parseFloat(K_cond.dflt_rhExtC).toFixed(K_min_max_dec.decRh),
      taExtC: parseFloat(K_cond.dflt_taExtC).toFixed(K_min_max_dec.decT),
      twInC: parseFloat(K_cond.std_twInC).toFixed(K_min_max_dec.decT),
      twOutC: parseFloat(K_cond.std_twOutC).toFixed(K_min_max_dec.decT),
      qwC: parseFloat(K_cond.dflt_qw).toFixed(K_min_max_dec.decQw),
      // H
      taInH: parseFloat(K_cond.dflt_taInH).toFixed(K_min_max_dec.decT),
      taExtH: parseFloat(K_cond.dflt_taExtH).toFixed(K_min_max_dec.decT),
      taIntH: parseFloat(K_cond.dflt_taIntH).toFixed(K_min_max_dec.decT),
      percentAirExtH: parseFloat(K_cond.dflt_percentAirExtH).toFixed(
        K_min_max_dec.decPercAir
      ),
      twInH: parseFloat(K_cond.std_twInH).toFixed(K_min_max_dec.decT),
      twOutH: parseFloat(K_cond.std_twOutH).toFixed(K_min_max_dec.decT),
      qwH: parseFloat(K_cond.dflt_qw).toFixed(K_min_max_dec.decQw),
    };
  }

  checkBounds(value, lowerBound, upperBound) {
    return value >= lowerBound && value <= upperBound;
  }

  checkNumber(value) {
    return !isNaN(value);
  }

  isValid(label, value) {
    value = parseFloat(value);

    switch (label) {
      case "roomLen":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minRoomLen,
            K_min_max_dec.maxRoomLen
          )
        );
      case "roomWid":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minRoomWid,
            K_min_max_dec.maxRoomWid
          )
        );
      case "roomHei":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minRoomHei,
            K_min_max_dec.maxRoomHei
          )
        );
      case "tRiv":
        return (
          this.checkNumber(value) &&
          this.checkBounds(value, K_min_max_dec.minTrev, K_min_max_dec.maxTrev)
        );
      case "sabine":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minSabine,
            K_min_max_dec.maxSabine
          )
        );
      case "micSorg":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minMicSrc,
            K_min_max_dec.maxMicSrc
          )
        );
      case "altitu":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minAltitu,
            K_min_max_dec.maxAltitu
          )
        );
      case "esp":
        return (
          this.checkNumber(value) &&
          this.checkBounds(value, K_min_max_dec.minEsp, K_min_max_dec.maxEsp)
        );
      // C
      case "taInC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTaInC,
            K_min_max_dec.maxTaInC
          )
        );
      case "rhInC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minRhInC,
            K_min_max_dec.maxRhInC
          )
        );
      case "taBuInC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTwbInC,
            K_min_max_dec.maxTwbInC
          )
        );
      case "percentAirExtC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minPercentAirExtC,
            K_min_max_dec.maxPercentAirExtC
          )
        );
      case "taExtC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTaExtC,
            K_min_max_dec.maxTaExtC
          )
        );
      case "rhExtC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minRhExtC,
            K_min_max_dec.maxRhExtC
          )
        );
      case "taIntC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTaIntC,
            K_min_max_dec.maxTaIntC
          )
        );
      case "rhIntC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minRhIntC,
            K_min_max_dec.maxRhIntC
          )
        );
      case "twInC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTwInC,
            K_min_max_dec.maxTwInC
          )
        );
      case "twOutC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTwOutC,
            K_min_max_dec.maxTwOutC
          )
        );
      case "qwC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(value, K_min_max_dec.minQwC, K_min_max_dec.maxQwC)
        );
      case "deltaTC":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minDeltaTC,
            K_min_max_dec.maxDeltaTC
          )
        );
      // H
      case "taInH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTaInH,
            K_min_max_dec.maxTaInH
          )
        );
      case "taExtH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTaExtH,
            K_min_max_dec.maxTaExtH
          )
        );
      case "taIntH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTaIntH,
            K_min_max_dec.maxTaIntH
          )
        );
      case "percentAirExtH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minPercentAirExtH,
            K_min_max_dec.maxPercentAirExtH
          )
        );
      case "twInH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTwInH,
            K_min_max_dec.maxTwInH
          )
        );
      case "twOutH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minTwOutH,
            K_min_max_dec.maxTwOutH
          )
        );
      case "qwH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(value, K_min_max_dec.minQwH, K_min_max_dec.maxQwH)
        );
      case "deltaTH":
        return (
          this.checkNumber(value) &&
          this.checkBounds(
            value,
            K_min_max_dec.minDeltaTH,
            K_min_max_dec.maxDeltaTH
          )
        );
      default:
        break;
    }
  }

  setFallBackValue(variableName, value) {
    switch (variableName) {
      case "roomLen":
        this.fallBackVal.roomLen = parseFloat(value).toFixed(
          K_min_max_dec.decMt
        );
        break;
      case "roomWid":
        this.fallBackVal.roomWid = parseFloat(value).toFixed(
          K_min_max_dec.decMt
        );
        break;
      case "roomHei":
        this.fallBackVal.roomHei = parseFloat(value).toFixed(
          K_min_max_dec.decMt
        );
        break;
      case "tRiv":
        this.fallBackVal.tRiv = parseFloat(value).toFixed(
          K_min_max_dec.decTrev
        );
        break;
      case "sabine":
        this.fallBackVal.sabine = parseFloat(value).toFixed(
          K_min_max_dec.decSab
        );
        break;
      case "micSorg":
        this.fallBackVal.micSorg = parseFloat(value).toFixed(
          K_min_max_dec.decMicSrc
        );
        break;
      case "altitu":
        this.fallBackVal.altitu = parseFloat(value).toFixed(
          K_min_max_dec.decAltitu
        );
        break;
      case "esp":
        this.fallBackVal.esp = parseFloat(value).toFixed(K_min_max_dec.decEsp);
        break;
      // C
      case "taInC":
        this.fallBackVal.taInC = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "rhInC":
        this.fallBackVal.rhInC = parseFloat(value).toFixed(K_min_max_dec.decRh);
        break;
      case "taBuInC":
        this.fallBackVal.taBuInC = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "percentAirExtC":
        this.fallBackVal.percentAirExtC = parseFloat(value).toFixed(
          K_min_max_dec.decPercAir
        );
        break;
      case "taExtC":
        this.fallBackVal.taExtC = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "rhExtC":
        this.fallBackVal.rhExtC = parseFloat(value).toFixed(
          K_min_max_dec.decRh
        );
        break;
      case "taIntC":
        this.fallBackVal.taIntC = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "rhIntC":
        this.fallBackVal.rhIntC = parseFloat(value).toFixed(
          K_min_max_dec.decRh
        );
        break;
      case "twInC":
        this.fallBackVal.twInC = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "twOutC":
        this.fallBackVal.twOutC = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "qwC":
        this.fallBackVal.qwC = parseFloat(value).toFixed(K_min_max_dec.decQw);
        break;
      // H
      case "taExtH":
        this.fallBackVal.taExtH = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "taIntH":
        this.fallBackVal.taIntH = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "percentAirExtH":
        this.fallBackVal.percentAirExtC = parseFloat(value).toFixed(
          K_min_max_dec.decPercAir
        );
        break;
      case "taInH":
        this.fallBackVal.taInH = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "twInH":
        this.fallBackVal.twInH = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "twOutH":
        this.fallBackVal.twOutH = parseFloat(value).toFixed(
          K_min_max_dec.decT
        );
        break;
      case "qwH":
        this.fallBackVal.qwH = parseFloat(value).toFixed(K_min_max_dec.decQw);
        break;
      default:
        break;
    }
  }

  fallBackValue(variableName) {
    switch (variableName) {
      case "roomLen":
        return this.fallBackVal.roomLen;
      case "roomWid":
        return this.fallBackVal.roomWid;
      case "roomHei":
        return this.fallBackVal.roomHei;
      case "tRiv":
        return this.fallBackVal.tRiv;
      case "sabine":
        return this.fallBackVal.sabine;
      case "micSorg":
        return this.fallBackVal.micSorg;
      case "altitu":
        return this.fallBackVal.altitu;
      case "esp":
        return this.fallBackVal.esp;
      // C
      case "taInC":
        return this.fallBackVal.taInC;
      case "rhInC":
        return this.fallBackVal.rhInC;
      case "taBuInC":
        return this.fallBackVal.taBuInC;
      case "percentAirExtC":
        return this.fallBackVal.percentAirExtC;
      case "taExtC":
        return this.fallBackVal.taExtC;
      case "rhExtC":
        return this.fallBackVal.rhExtC;
      case "taIntC":
        return this.fallBackVal.taIntC;
      case "rhIntC":
        return this.fallBackVal.rhIntC;
      case "twInC":
        return this.fallBackVal.twInC;
      case "twOutC":
        return this.fallBackVal.twOutC;
      case "qwC":
        return this.fallBackVal.qwC;
      // H
      case "taInH":
        return this.fallBackVal.taInH;
      case "percentAirExtH":
        return this.fallBackVal.percentAirExtH;
      case "taExtH":
        return this.fallBackVal.taExtH;
      case "taIntH":
        return this.fallBackVal.taIntH;
      case "twInH":
        return this.fallBackVal.twInH;
      case "twOutH":
        return this.fallBackVal.twOutH;
      case "qwH":
        return this.fallBackVal.qwH;
      default:
        break;
    }
  }
}

export default Validator;
