import React from "react";
import onClickOutside from "react-onclickoutside";
import "./inputBox.css";

class InputBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInputSel: false,
    };
  }

  _calcClassName() {
    let classname = "";
    if (this.props.loginInputBox) classname += " login-input-box";

    if (this.props.className !== undefined) {
      if (this.props.className.indexOf("blink") > -1) classname += " blink";
    }

    if (this.props.isDisabled) classname += " disabled_mc";

    return classname;
  }

  _calcType() {
    if (this.props.isPassword) return "password";
    else return "text";
  }

  _onClicked(e) {
    let eModified = e;
    eModified.target.value = "";
    this.setState({ isInputSel: true });
    this.props.onChange(eModified);
  }

  handleClickOutside() {
    if (this.state.isInputSel && !this.props.loginInputBox) {
      let fakeEvent = {
        key: "Enter",
        target: {
          name: this.props.name,
          value: this.props.value,
        },
      };
      this.setState({ isInputSel: false });
      this.props.onKeyPress(fakeEvent);
    } else return;
  }

  render() {
    return (
      <>
        <input
          disabled={this.props.isDisabled}
          key={this.props.key}
          name={this.props.name}
          placeholder={this.props.placeholder}
          value={this.props.value}
          className={this._calcClassName()}
          onChange={(e) => this.props.onChange(e)}
          onClick={(e) => this._onClicked(e)}
          onKeyPress={(e) => this.props.onKeyPress(e)}
          type={this._calcType()}
        />
      </>
    );
  }
}

export default onClickOutside(InputBox);
