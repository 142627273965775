import React from "react";
import { withNamespaces } from "react-i18next";
import InputBox from "../../../../_components/InputBox";
const K_min_max_dec = require("../../../../../assets/support/__k_min_max_dec");

class TableCFluidSide extends React.Component {
  componentDidMount() {
    this.props.cond.twInC = parseFloat(this.props.cond.twInC).toFixed(
      K_min_max_dec.decT
    );
    this.props.cond.twOutC = parseFloat(this.props.cond.twOutC).toFixed(
      K_min_max_dec.decT
    );
  }

  _getQwC() {
    if (!this.props.visSett.isTwOutCdisplayed) return this.props.cond.qwC;
    else return "";
  }

  _getTwOut() {
    if (this.props.visSett.isTwOutCdisplayed) return this.props.cond.twOutC;
    else return "";
  }

  _setFluidC(e) {
    let cond2 = this.props.cond;
    cond2.fluidoC = e.target.value;
    this.props.setCond(cond2);
  }

  onChange(e) {
    let visSett2 = this.props.visSett,
      cond2 = this.props.cond;

    switch (e.target.name) {
      case "twInC":
        cond2.twInC = e.target.value;
        break;

      case "twOutC":
        cond2.twOutC = e.target.value;
        visSett2.isTwOutCdisplayed = true;
        this.props.setVisSett(visSett2);
        break;

      case "qwC":
        cond2.qwC = e.target.value;
        visSett2.isTwOutCdisplayed = false;
        this.props.setVisSett(visSett2);
        break;

      default:
        break;
    }
    this.props.setCond(cond2);
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      let cond2 = this.props.cond,
        visSett2 = this.props.visSett;

      if (!this.props.validator.isValid(e.target.name, e.target.value)) {
        if (
          !(
            e.target.value === undefined ||
            isNaN(e.target.value) ||
            e.target.value === ""
          )
        )
          this.props.displayPopUpSms(this.props.t("err_" + e.target.name));

        // Fallback values
        cond2.twInC = this.props.validator.fallBackValue("twInC");
        cond2.twOutC = this.props.validator.fallBackValue("twOutC");
        cond2.qwC = this.props.validator.fallBackValue("qwC");

        this.props.setCond(cond2);

        return;
      }

      switch (e.target.name) {
        case "twInC":
          cond2.twInC = parseFloat(e.target.value).toFixed(K_min_max_dec.decT);
          this.props.validator.setFallBackValue("twInC", cond2.twInC);
          break;

        case "twOutC":
          cond2.twOutC = parseFloat(e.target.value).toFixed(K_min_max_dec.decT);
          this.props.validator.setFallBackValue("twOutC", cond2.twOutC);
          visSett2.isTwOutCdisplayed = true;
          this.props.setVisSett(visSett2);
          break;

        case "qwC":
          cond2.qwC = parseFloat(e.target.value).toFixed(K_min_max_dec.decQw);
          this.props.validator.setFallBackValue("qwC", cond2.qwC);
          visSett2.isTwOutCdisplayed = false;
          this.props.setVisSett(visSett2);
          break;

        default:
          break;
      }

      this.props.setCond(cond2);
    }
  }

  render() {
    return (
      <table id="tableWattC" className="table caption-on-top">
        <caption className="text-center table-caption">
          {this.props.title}
        </caption>
        <tbody>
          <tr>
            <td>
              <label>{this.props.t("fluidType")}</label>
            </td>
            <td>
              <select
                className={
                  this.props.visSett.blinkC || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
                onChange={e => this._setFluidC(e)}
                value={this.props.cond.fluidoC}
              >
                <option value="acqua">{this.props.t("water")}</option>
                <option value="eti5">
                  {this.props.t("ethGlycol") + " 5%"}
                </option>
                <option value="eti10">
                  {this.props.t("ethGlycol") + " 10%"}
                </option>
                <option value="eti15">
                  {this.props.t("ethGlycol") + " 15%"}
                </option>
                <option value="eti20">
                  {this.props.t("ethGlycol") + " 20%"}
                </option>
                <option value="eti25">
                  {this.props.t("ethGlycol") + " 25%"}
                </option>
                <option value="eti30">
                  {this.props.t("ethGlycol") + " 30%"}
                </option>
                <option value="eti35">
                  {this.props.t("ethGlycol") + " 35%"}
                </option>
                <option value="eti40">
                  {this.props.t("ethGlycol") + " 40%"}
                </option>
                <option value="eti45">
                  {this.props.t("ethGlycol") + " 45%"}
                </option>
                <option value="eti50">
                  {this.props.t("ethGlycol") + " 50%"}
                </option>
                <option value="pro15">
                  {this.props.t("propGlycol") + " 15%"}
                </option>
                <option value="pro25">
                  {this.props.t("propGlycol") + " 25%"}
                </option>
                <option value="pro35">
                  {this.props.t("propGlycol") + " 35%"}
                </option>
                <option value="pro40">
                  {this.props.t("propGlycol") + " 40%"}
                </option>
                <option value="pro45">
                  {this.props.t("propGlycol") + " 45%"}
                </option>
                <option value="pro50">
                  {this.props.t("propGlycol") + " 50%"}
                </option>
                <option value="pro55">
                  {this.props.t("propGlycol") + " 55%"}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>{this.props.t("tIn_abbr") + " [°C]"}</td>
            <td colSpan="2">
              <InputBox
                className={
                  this.props.visSett.blinkC || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
                key="twInC"
                name="twInC"
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
                value={this.props.cond.twInC}
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("tOut_abbr") + " [°C]"}</td>
            <td colSpan="2">
              <InputBox
                className={
                  this.props.visSett.blinkC || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
                key="twOutC"
                name="twOutC"
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
                value={this._getTwOut()}
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("flowFluid") + " [l/h]"}</td>
            <td colSpan="2">
              <InputBox
                className={
                  this.props.visSett.blinkC || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
                key="qwC"
                name="qwC"
                onChange={e => this.onChange(e)}
                onKeyPress={e => this.onKeyPress(e)}
                value={this._getQwC()}
                type="text"
              />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withNamespaces()(TableCFluidSide);
