import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import InputBox from "../_components/InputBox";
import Lang from "../_components/Lang";
import PopUp from "../_components/PopUp";
import Btn from "../_components/Btn";
import "./login.css";
const K_varie = require("../../assets/support/__k_varie");

class Login extends Component {
  constructor(props) {
    super(props);

    this.popup = React.createRef();
    this.state = {
      privacyIsVisible: true,
      usr: K_varie.empty,
      pwd: K_varie.empty,
      logoSW: null,
      logoAzienda: null,
    };
  }

  componentDidMount() {
    document.body.className = "body-login";

    let logoSWimg, logoAziendaImg;
    if (this.props.config.isEura) {
      logoSWimg = require("../../assets/images/logoGeniusplanTrasparenteLogin.png");
      logoAziendaImg = require("../../assets/images/logoEurapoTrasparenteLogin.png");
    } else {
      logoSWimg = require("../../assets/images/logoLaserTrasparenteLogin.png");
      logoAziendaImg = require("../../assets/images/logoYorkTrasparenteLogin.png");
    }

    this.setState({ logoSW: logoSWimg, logoAzienda: logoAziendaImg });
    // caso desktop, per non doversi loggare ogni volta
    if (!this.props.config.isServer) {
      const { ipcRenderer } = window.require("electron");

      ipcRenderer.once("loggedChecked", (event, arg) => {
        let config2 = this.props.config;
        config2.logged = arg.logged;
        config2.idU = arg.idU;

        this.props.setConfig(config2);
      });

      ipcRenderer.send("checkLogged", {});
    }
  }

  _calcClassPrivacy() {
    if (this.state.privacyIsVisible) return "alert-warning text-center";
    else return "alert-warning text-center notVisible";
  }

  _expPrivacy() {
    (async () => {
      if (this.props.config.isServer) {
        this.props.toggleOverlay();

        const resp = await this.props.getResWeb("download/privacyPolicy", {
            usrSett: this.props.usrSett,
          }),
          response = {
            file:
              this.props.config.urlServer +
              "download/privacyPolicyGenerated/?filename=" +
              encodeURIComponent(resp),
          };

        window.open(response.file);
        this.props.toggleOverlay();
      }
    })();
  }

  _handleKeyPress(e) {
    if (e.key === "Enter") this._loginPressed();
  }

  _loginPressed() {
    if (this.props.config.isServer) {
      (async () => {
        this.props.toggleOverlay();
        const id = await this.props.getResWeb("compute/login", {
          usr: this.state.usr,
          pwd: this.state.pwd,
        });

        if (id > K_varie.idUsrUndefined) {
          let config2 = this.props.config;
          config2.logged = true;
          config2.idU = id;

          this.props.setConfig(config2);
          this.props.toggleOverlay();
        } else {
          let config2 = this.props.config;
          config2.logged = false;
          config2.idU = K_varie.idUsrUndefined;

          this.props.displayPopUpSms(this.props.t("err_credentials"));
          this.props.setConfig(config2);
          this.props.toggleOverlay();
        }
      })();
    } else {
      const { ipcRenderer } = window.require("electron");

      ipcRenderer.once("loginResp", (event, arg) => {
        if (arg.idU < 0)
          this.props.displayPopUpSms(this.props.t("err_credentials"));

        let config2 = this.props.config;
        config2.logged = arg.logged;
        config2.idU = arg.idU;

        this.props.setConfig(config2);
      });

      ipcRenderer.send("login", {
        usr: this.state.usr,
        pwd: this.state.pwd,
      });
    }

    return;
  }

  _setPrivacyClass() {
    this.setState({ privacyIsVisible: false });
  }

  _setUsrPwd(e) {
    switch (e.target.name) {
      case "usr":
        this.setState({ usr: e.target.value });
        break;
      case "pwd":
        this.setState({ pwd: e.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    let cellClass = "cell-title text-center title-fix",
      cellSubtitleClass = "cell-subtitle text-center subtitle-fix";
    let privacyDiv, privacyEura, imgSw;

    if (this.props.config.isEura) {
      privacyEura = (
        <>
          <span>{this.props.t("privacyDiv1")}</span>
          <span>
            <Btn
              value="privacy policy"
              onClick={() => this._expPrivacy()}
              extraClassName="isRed"
              isChecked={true}
            />
          </span>
        </>
      );
      cellClass += " title-fixEura";
      cellSubtitleClass += " cell-subtitle-eura";
    } else {
      cellClass += " title-fixYork";
      cellSubtitleClass += " cell-subtitle-york";
    }

    if (this.props.config.isServer)
      privacyDiv = (
        <>
          <div id="coo-cell" className={this._calcClassPrivacy()}>
            <span id="coo-cell-text">
              {privacyEura}
              <span>{this.props.t("privacyDiv2")}</span>.
            </span>
            <Btn
              value={this.props.t("accept")}
              onClick={() => this._setPrivacyClass()}
              isVisible={this.state.privacyIsVisible}
              extraClassName="isGreen"
            />
          </div>
        </>
      );

    imgSw = (
      <>
        <div className={cellClass}>
          <img src={this.state.logoSW} alt="LogoSW" className="image-sw-fix" />
        </div>
      </>
    );

    return (
      <>
        <div id="wrapper-login">
          <PopUp
            isVisible={this.props.visSett.popUpIsVis}
            labelPopUpSms={this.props.visSett.labelPopUpSms}
          />
          <div id="contiene-login">
            <div id="spazia-login" />
            <div className="cell login-box-fix">
              {imgSw}
              <div className={cellSubtitleClass}>
                <img
                  src={this.state.logoAzienda}
                  alt="Logo"
                  className="image-fix"
                />
              </div>
              <div id="spazio-in-mezzo-al-login" />
              <div className="text-center">
                <InputBox
                  loginInputBox={true}
                  name="usr"
                  placeholder="Username"
                  onChange={(e) => this._setUsrPwd(e)}
                  onKeyPress={(e) => this._handleKeyPress(e)}
                />
                <InputBox
                  isPassword={true}
                  loginInputBox={true}
                  name="pwd"
                  placeholder="Password"
                  onChange={(e) => this._setUsrPwd(e)}
                  onKeyPress={(e) => this._handleKeyPress(e)}
                />
                <Btn
                  value="LOGIN"
                  onClick={() => this._loginPressed()}
                  extraClassName="login-button-box"
                />
              </div>
              <div id="spazio-in-mezzo-al-login" />
              <div className="text-center">
                <Lang
                  isDisabled={false}
                  usrSett={this.props.usrSett}
                  setLang={(c) => this.props.setLang(c)}
                  setUsrSett={(u) => this.props.setUsrSett(u)}
                />
              </div>
            </div>
          </div>
          {privacyDiv}
        </div>
      </>
    );
  }
}

export default withNamespaces()(Login);
