import React from "react";
import { withNamespaces } from "react-i18next";
import EllipsisText from "react-ellipsis-text";
import _ from "lodash";
import ColBtns from "../../../_components/ColBtns";
import "./colSpeed.css";
const K_cond = require("../../../../assets/support/__k_cond"),
  arrayStep = [K_cond.stepMax, K_cond.stepMed, K_cond.stepMin];

class ColSpeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idxMin: 0,
      idxMax: 9,
      idxStep: 0,
    };
  }

  componentDidMount() {
    if (!this.props.brs) this._setSpeedList(this.props.btnSel);
  }

  _boolToRange(arrayBool) {
    let rangeArray = _.range(1, arrayBool.length + 1);

    return rangeArray.filter((el, idx) => arrayBool[idx] === true);
  }

  _getSpeedStep() {
    switch (parseFloat(this.props.cond.step)) {
      case K_cond.stepMax:
        return 0;
      case K_cond.stepMed:
        return 1;
      case K_cond.stepMin:
        return 2;
      default:
        return 0;
    }
  }

  _setSpeedMin(e) {
    // se valore utente è minore = dell'attuale massimo della lista Vel
    if (e.target.value < this.props.cond.listaVel.slice(-1)[0])
      this._setSpeedRng2(e.target.value, this.state.idxMax);
  }

  _setSpeedMax(e) {
    // se valore utente è maggiore = dell'attuale minimo della lista Vel
    if (e.target.value >= this.state.idxMin)
      this._setSpeedRng2(this.state.idxMin, e.target.value);
  }

  _setSpeedList(arrayVel) {
    let cond2 = this.props.cond;
    cond2.listaVel = this._boolToRange(arrayVel);
    this.props.setCond(cond2);
  }

  _setSpeedRng2(idxMin, idxMax) {
    if (this.props.brs) {
      let boolArray = Array(this.props.btnLbls.length).fill(false);
      this.setState({ idxMin: idxMin });
      this.setState({ idxMax: idxMax });
      boolArray = boolArray.map((el, idx) => {
        if (idx >= idxMin && idx <= idxMax) return true;
        else return false;
      });

      this._setSpeedList(boolArray);
    }
  }

  _setSpeedStep(e) {
    let cond2 = this.props.cond;
    cond2.step = parseFloat(arrayStep[e.target.value]);
    this.props.setCond(cond2);
  }

  _setSpeedAsinc(i) {
    let btnSel2 = this.props.btnSel;
    btnSel2[i] = !btnSel2[i];

    if (btnSel2.indexOf(true) < 0) btnSel2[i] = !btnSel2[i];

    this._setSpeedList(btnSel2);
  }

  selectAllSpeed(e) {
    let btnSel2 = this.props.btnSel;

    for (let i = 0; i < btnSel2.length; i++) {
      btnSel2[i] = true;
    }

    this._setSpeedList(btnSel2);
  }

  deSelectAllSpeed(e) {
    let btnSel2 = this.props.btnSel;

    for (let i = 0; i < btnSel2.length; i++) {
      btnSel2[i] = false;
    }

    this._setSpeedList(btnSel2);
  }

  render() {
    let displayBox = (
      <ColBtns
        title={this.props.title}
        btnLbls={this.props.btnLbls}
        btnSel={this.props.btnSel}
        nCol={2}
        isSelAllEnabled={!this.props.brs}
        usrSett={this.props.usrSett}
        textLen={"11"}
        deSelectAll={(e) => this.deSelectAllSpeed(e)}
        onClick={(i) => this._setSpeedAsinc(i)}
        selectAll={(e) => this.selectAllSpeed(e)}
      />
    );

    if (this.props.brs) {
      let optionsFrom = [],
        optionsTo = [],
        stepOptions = [],
        colTitle =
          this.props.usrSett.lang === "ge" ? (
            <EllipsisText text={this.props.title} length={this.props.textLen} />
          ) : (
            this.props.title
          );

      for (let i = 0; i < this.props.cond.listaVel.slice(-1)[0]; i++) {
        optionsFrom.push(
          <option value={i} key={"From" + i}>
            {this.props.btnLbls[i]}
          </option>
        );
      }

      for (let i = this.state.idxMin; i < 10; i++) {
        optionsTo.push(
          <option value={i} key={"To" + i}>
            {this.props.btnLbls[i]}
          </option>
        );
      }

      for (let i = 0; i < arrayStep.length; i++) {
        stepOptions.push(
          <option value={i} key={"step" + i}>
            {arrayStep[i]}
          </option>
        );
      }

      displayBox = (
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2">
          <div className="cell-title text-center">
            <h4 className="text-center">{colTitle}</h4>
          </div>
          <div className="text-center">
            <table>
              <tbody>
                <tr className="text-center">
                  <td className="text-center td-center-fix">{"VOLT"}</td>
                </tr>
                <tr className="rigaFrom">
                  <td>{this.props.t("from")}</td>
                  <td>
                    <select
                      className="select-vel"
                      value={this.props.cond.listaVel[0] - 1}
                      id="fromVolt"
                      key="fromVolt"
                      onChange={(e) => this._setSpeedMin(e)}
                    >
                      {optionsFrom}
                    </select>
                  </td>
                </tr>
                <tr className="rigaTo">
                  <td>{this.props.t("to")}</td>
                  <td>
                    <select
                      className="select-vel"
                      value={this.props.cond.listaVel.slice(-1)[0] - 1}
                      id="toVolt"
                      key="toVolt"
                      onChange={(e) => this._setSpeedMax(e)}
                    >
                      {optionsTo}
                    </select>
                  </td>
                </tr>
                <tr className="rigaStep">
                  <td>{this.props.t("steps")}</td>
                  <td>
                    <select
                      className="select-vel"
                      value={this._getSpeedStep()}
                      id="stepVolt"
                      key="stepVolt"
                      onChange={(e) => this._setSpeedStep(e)}
                    >
                      {stepOptions}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }

    return displayBox;
  }
}

export default withNamespaces()(ColSpeed);
