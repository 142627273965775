import React from "react";
import "./popUp.css";

class PopUp extends React.Component {
  _calcClassName() {
    let nomeClasse = "allerta";

    if (this.props.isVisible) nomeClasse += " popup";
    if (this.props.labelPopUpSms.length > 78) nomeClasse += " duerighe";

    return nomeClasse;
  }

  render() {
    return (
      <>
        <div className={this._calcClassName()}>
          <p>{this.props.labelPopUpSms}</p>
        </div>
      </>
    );
  }
}

export default PopUp;
