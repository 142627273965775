import _ from "lodash";
const config = require("../assets/config");
let catalog;

if (config.isEura) catalog = require("../assets/models/_euraMacc");
else catalog = require("../assets/models/_yorkMacc");

class Catalog {
  constructor() {
    this.catalogoF = [];
  }

  getInitialDisVal() {
    return {
      std: catalog[2].std,
      brs: catalog[2].brs,
      range: [catalog[2].range],
      vers: [catalog[2].vers],
      model: [
        "110",
        "112",
        "114",
        "216",
        "218",
        "220",
        "222",
        "224",
        "226",
        "328",
      ],
      battP: [2, 3, 4],
      battA: [1],
      etiVel: catalog[2].etiVel,
      modiResa: catalog[2].modiResa,
      ducted: catalog[2].ducted,
      imagine: catalog[2].imagine,
    };
  }

  getInitialBtnChecked() {
    return {
      std: true,
      brs: false,
      range: [true, false, false, false, false, false, false],
      vers: [true, false, false, false],
      model: [true, true, true, true, true, true, true, true, true, true],
      battP: [false, true, false],
      battA: [false],
      etiVel: [false, true, true, false, true, false],
      modiResa: [0, 4, 1, 2],
      ducted: false,
      imagine: true,
    };
  }

  updateSel(GUIsel) {
    const { disVal, btnSel } = GUIsel,
      oldSerieLength = disVal.range.length,
      oldVersLength = disVal.vers.length,
      oldModelLength = disVal.model.length,
      oldBattAlen = disVal.battA.length,
      oldBattPlen = disVal.battP.length,
      oldListaVel = disVal.etiVel;
    let disVal2 = disVal,
      btnSel2 = btnSel,
      resetBattPDfltFLAG = false,
      serieAll,
      versAll,
      imageAll,
      modelAll,
      modelSel,
      battAall,
      battAsel,
      battPall,
      velAll,
      modiResaAll;
    this.catalogoF = catalog;

    // Filter Brushless and Standard
    this.catalogoF = catalog
      .filter((mac) => mac.brs === btnSel.brs)
      .filter((mac) => mac.std === btnSel.std);

    // List all possible Serie, update disVal2 & btnSel2, filter
    serieAll = this.catalogoF.reduce((n, mac) => n.concat(mac.range), []);
    disVal2.range = _.uniqBy(serieAll);
    if (disVal2.range.length !== oldSerieLength) {
      btnSel2.range = new Array(disVal2.range.length).fill(false);
      btnSel2.range[0] = true;
      resetBattPDfltFLAG = true;
    }
    this.catalogoF = this.catalogoF.filter(
      (mac) => mac.range === disVal2.range[btnSel2.range.indexOf(true)]
    );

    // List all possible Vers, update disVal2 & btnSel2, filter
    versAll = this.catalogoF.reduce((n, mac) => n.concat(mac.vers), []);
    disVal2.vers = _.uniqBy(versAll);
    if (disVal2.vers.length !== oldVersLength) {
      btnSel2.vers = new Array(disVal2.vers.length).fill(false);
      btnSel2.vers[0] = true;
      resetBattPDfltFLAG = true;
    }
    this.catalogoF = this.catalogoF.filter(
      (mac) => mac.vers === disVal2.vers[btnSel2.vers.indexOf(true)]
    );

    // List all possible images (should return a single one)
    imageAll = this.catalogoF.reduce((acc, curr) => {
      return acc.concat(curr.imagine);
    }, []);
    disVal2.imagine = _.uniqBy(imageAll)[0];
    // accrocchio per imagine UCS900
    if (
      this.catalogoF[0].range === "CASSETTA" ||
      this.catalogoF[0].range === "CASSETTE"
    ) {
      let selectedModels,
        is900 = true;
      selectedModels = disVal2.model.filter((m, index) => {
        let r;
        if (btnSel.model[index]) r = m;
        return r;
      });
      for (let m of selectedModels) {
        if (m[0] !== "9") {
          is900 = false;
          break;
        }
      }
      if (is900) disVal2.imagine = 22;
    }

    // List all possible Model, update disVal2 & btnSel2, filter
    modelAll = this.catalogoF.reduce((n, mac) => n.concat(mac.model), []);
    disVal2.model = _.uniqBy(modelAll);
    if (disVal2.model.length !== oldModelLength) {
      btnSel2.model = new Array(disVal2.model.length).fill(true);
    }
    modelSel = disVal.model.filter((e, id) => btnSel.model[id] === true);
    this.catalogoF = this.catalogoF.filter(
      (mac) => modelSel.indexOf(mac.model) > -1
    );

    // List all possible battA, update disVal2 & btnSel2, filter
    battAall = this.catalogoF.reduce((n, mac) => n.concat(mac.battA), []);
    disVal2.battA = _.uniqBy(battAall);
    if (disVal2.battA.length !== oldBattAlen) {
      btnSel2.battA = new Array(disVal2.battA.length).fill(false);
      btnSel2.battA[0] = true;
    }
    battAsel = disVal.battA.filter((e, id) => btnSel.battA[id] === true);
    this.catalogoF = this.catalogoF.filter(
      (mac) => battAsel.indexOf(mac.battA) > -1
    );

    // List all possible battP, update disVal2 & btnSel2, filter
    battPall = this.catalogoF.reduce((n, mac) => n.concat(mac.battP), []);
    disVal2.battP = _.uniqBy(battPall);
    // Set the Default battP
    if (resetBattPDfltFLAG || oldBattPlen !== disVal2.battP.length) {
      let indexBattPDefault = _.uniqBy(
        this.catalogoF
          .filter((mac) => mac.battPdefault === true)
          .reduce((accum, mac) => accum.concat(mac.battP), [])
      );
      btnSel2.battP = new Array(disVal2.battP.length).fill(false);
      indexBattPDefault.forEach(
        (ind) => (btnSel2.battP[disVal2.battP.indexOf(ind)] = true)
      );
    }
    let battPsel = disVal.battP.filter((e, id) => btnSel.battP[id] === true);
    this.catalogoF = this.catalogoF.filter(
      (mac) => battPsel.indexOf(mac.battP) > -1
    );

    // Set Speeds
    velAll = this.catalogoF.reduce((acc, el) => acc.concat(el.etiVel), []);
    disVal2.etiVel = _.uniqBy(velAll);
    if (oldListaVel !== disVal2.etiVel) {
      // Several different array of speeds become a single array without min-med-max
      if (disVal2.etiVel.length > 6) {
        disVal2.etiVel = ["1", "2", "3", "4", "5", "6"];
        btnSel2.etiVel = [true, true, true, true, true, true];
      } else {
        // Set the speed with min-med-max (i.e. that have more than one letter )
        btnSel2.etiVel = new Array(disVal2.etiVel.length).fill(false);
        btnSel2.etiVel = btnSel2.etiVel.map(
          (el, id) => (el = disVal2.etiVel[id].length > 1 ? true : false)
        );
      }
    }
    // Check Brushless
    if (btnSel2.brs) {
      disVal2.etiVel = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
      btnSel2.etiVel = [
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ];
    }

    // Set modiResa
    modiResaAll = this.catalogoF.reduce((a, e) => a.concat(e.modiResa), []);
    disVal2.modiResa = _.uniqBy(modiResaAll);
    btnSel2.modiResa = new Array(disVal2.modiResa.length).fill(false);
    btnSel2.modiResa[0] = true;
    // se non c'è un modoResa in comune
    if (disVal2.modiResa.length === 0) {
      disVal2.modiResa = ["none"];
      return {
        disVal: disVal2,
        btnSel: btnSel2,
      };
    }

    // Set ducted
    btnSel2.ducted = this.catalogoF[0].ducted;
    disVal2.ducted = this.catalogoF[0].ducted;

    return {
      disVal: disVal2,
      btnSel: btnSel2,
    };
  }

  getIdSelMacc(GUIsel) {
    const { disVal, btnSel } = GUIsel;
    let modelSel = disVal.model.filter((e, id) => btnSel.model[id] === true),
      battAsel = disVal.battA.filter((e, id) => btnSel.battA[id] === true),
      battPsel = disVal.battP.filter((e, id) => btnSel.battP[id] === true);

    // Filter scrolling all Catalog
    return catalog
      .filter((mac) => mac.brs === btnSel.brs)
      .filter((mac) => mac.std === btnSel.std)
      .filter((mac) => mac.range === disVal.range[btnSel.range.indexOf(true)])
      .filter((mac) => mac.vers === disVal.vers[btnSel.vers.indexOf(true)])
      .filter((mac) => modelSel.indexOf(mac.model) > -1)
      .filter((mac) => battAsel.indexOf(mac.battA) > -1)
      .filter((mac) => battPsel.indexOf(mac.battP) > -1)
      .reduce((ids, mac) => {
        return ids.concat(mac.idMac);
      }, []);
  }
}

export default Catalog;
