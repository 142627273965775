import React, { Component } from "react";
import EllipsisText from "react-ellipsis-text";
import Btn from "../Btn";
import "./colBtns.css";

class ColBtns extends Component {
  _calcClassName() {
    return this.props.twoColumns ? "doublecolumns" : "";
  }

  _setNcol() {
    return (
      "col-xl-" +
      this.props.nCol +
      " col-lg-" +
      this.props.nCol +
      " col-md-" +
      this.props.nCol +
      " col-sm-" +
      this.props.nCol +
      " col-xs-" +
      this.props.nCol
    );
  }

  render() {
    //let textLen = "10";

    this.buttons = [];
    for (let i = 0; i < this.props.btnLbls.length; i++) {
      // metto questa condizione, per evitare di avere lo zero nella lista di battA
      if (!(this.props.btnLbls[i] === 0))
        this.buttons.push(
          <Btn
            key={this.props.btnLbls[i]}
            value={this.props.btnLbls[i]}
            onClick={() => this.props.onClick(i)}
            isChecked={this.props.btnSel[i]}
            btn_width_xxs={this.props.btn_width_xxs}
            btn_width_s={this.props.btn_width_s}
          />
        );
    }

    let selAllBtns;
    if (this.props.isSelAllEnabled) {
      selAllBtns = (
        <>
          <div className="button-all-myfix">
            <p
              name="selectAll"
              className={"tickbox"}
              onClick={(e) => this.props.selectAll(e)}
            >
              {"\uD83D\uDDF9"}
            </p>
            <p
              name="deSelectAll"
              className={"tickbox tickbox-deselect"}
              onClick={(e) => this.props.deSelectAll(e)}
            >
              {"\u2610"}
            </p>
          </div>
        </>
      );
    }

    let colTitle =
      this.props.usrSett.lang === "ge" ? (
        <EllipsisText text={this.props.title} length={this.props.textLen} />
      ) : (
        this.props.title
      );

    return (
      <>
        <div className={this._setNcol()}>
          <div className="cell-title text-center">
            <h4 className="text-center">{colTitle}</h4>
            {selAllBtns}
          </div>
          <div className="text-center">
            <div className={this._calcClassName()}>{this.buttons}</div>
          </div>
        </div>
      </>
    );
  }
}

export default ColBtns;
