import React from "react";
import { withNamespaces } from "react-i18next";
import InputBox from "../../../../_components/InputBox";
import Btn from "../../../../_components/Btn";
import "./styles.css";
const K_min_max_dec = require("../../../../../assets/support/__k_min_max_dec"),
  K_backend = require("../../../../../assets/support/__k_backend");

class TableHFluidSide extends React.Component {
  componentDidMount() {
    this.props.cond.twInH = parseFloat(this.props.cond.twInH).toFixed(
      K_min_max_dec.decT
    );
    this.props.cond.twOutH = parseFloat(this.props.cond.twOutH).toFixed(
      K_min_max_dec.decT
    );
  }

  _getQwH() {
    if (!this.props.visSett.isTwOutHdisplayed) return this.props.cond.qwH;
    else return "";
  }

  _getTwOut() {
    if (this.props.visSett.isTwOutHdisplayed) return this.props.cond.twOutH;
    else return "";
  }

  _setFluidH(e) {
    let cond2 = this.props.cond;
    cond2.fluidoH = e.target.value;
    this.props.setCond(cond2);
  }

  onChange(e) {
    if (isNaN(e.target.value)) return;

    let visSett2 = this.props.visSett,
      cond = this.props.cond;
    switch (e.target.name) {
      case "twInH":
        cond.twInH = e.target.value;
        break;

      case "twOutH":
        cond.twOutH = e.target.value;
        visSett2.isTwOutHdisplayed = true;
        this.props.setVisSett(visSett2);
        break;

      case "qwH":
        cond.qwH = e.target.value;
        visSett2.isTwOutHdisplayed = false;
        this.props.setVisSett(visSett2);
        break;

      case "flowCbtn":
        cond.modoResa =
          cond.modoResa === K_backend.modoResaCH2pFlowC
            ? K_backend.modoResaCH2p
            : K_backend.modoResaCH2pFlowC;

        // deseleziono battAux se selezionate
        if (this.props.GUIsel.disVal.battA.indexOf(0) > -1) {
          let maccSelData2 = this.props.GUIsel;

          maccSelData2.btnSel.battA = new Array(
            maccSelData2.btnSel.battA.length
          ).fill(false);
          maccSelData2.btnSel.battA[0] = true;
          this.props.setCond(this.props.cond);

          this.props.blinkGroup("flowC");

          return;
        }
        break;
      default:
        break;
    }
    this.props.setCond(cond);
  }

  onKeyPress(e) {
    if (e.key === "Enter") {
      let cond = this.props.cond,
        visSett2 = this.props.visSett;

      if (!this.props.validator.isValid(e.target.name, e.target.value)) {
        if (
          !(
            e.target.value === undefined ||
            isNaN(e.target.value) ||
            e.target.value === ""
          )
        )
          this.props.displayPopUpSms(this.props.t("err_" + e.target.name));

        // Fallback values
        cond.twInH = this.props.validator.fallBackValue("twInH");
        cond.twOutH = this.props.validator.fallBackValue("twOutH");
        cond.qwH = this.props.validator.fallBackValue("qwH");

        this.props.setCond(cond);

        return;
      }

      switch (e.target.name) {
        case "twInH":
          cond.twInH = parseFloat(e.target.value).toFixed(K_min_max_dec.decT);
          this.props.validator.setFallBackValue("twInH", cond.twInH);
          break;

        case "twOutH":
          cond.twOutH = parseFloat(e.target.value).toFixed(K_min_max_dec.decT);
          this.props.validator.setFallBackValue("twOutH", cond.twOutH);
          visSett2.isTwOutHdisplayed = true;
          this.props.setVisSett(visSett2);
          break;

        case "qwH":
          cond.qwH = parseFloat(e.target.value).toFixed(K_min_max_dec.decQw);
          this.props.validator.setFallBackValue("qwH", cond.qwH);
          visSett2.isTwOutHdisplayed = false;
          this.props.setVisSett(visSett2);
          break;

        default:
          break;
      }
      this.props.setCond(cond);
    }
  }

  render() {
    let classNameQw, classNameTout;
    if (this.props.visSett.blinkFlowC) classNameQw += "blink";
    if (this.props.visSett.blinkH) classNameTout += "blink";
    if (!this.props.visSett.isQwHenabled) classNameQw += " disabled_mc";
    if (!this.props.visSett.isToutHenabled) classNameTout += " disabled_mc";

    return (
      <table id="tableWattC" className="table caption-on-top">
        <caption className="text-center table-caption">
          {this.props.title}
        </caption>
        <tbody>
          <tr>
            <td>
              <label>{this.props.t("fluidType")}</label>
            </td>
            <td>
              <select
                className={this.props.visSett.blinkH ? "blink" : ""}
                onChange={e => this._setFluidH(e)}
                value={this.props.cond.fluidoH}
              >
                <option value="acqua">{this.props.t("water")}</option>
                <option value="eti5">
                  {this.props.t("ethGlycol") + " 5%"}
                </option>
                <option value="eti10">
                  {this.props.t("ethGlycol") + " 10%"}
                </option>
                <option value="eti15">
                  {this.props.t("ethGlycol") + " 15%"}
                </option>
                <option value="eti20">
                  {this.props.t("ethGlycol") + " 20%"}
                </option>
                <option value="eti25">
                  {this.props.t("ethGlycol") + " 25%"}
                </option>
                <option value="eti30">
                  {this.props.t("ethGlycol") + " 30%"}
                </option>
                <option value="eti35">
                  {this.props.t("ethGlycol") + " 35%"}
                </option>
                <option value="eti40">
                  {this.props.t("ethGlycol") + " 40%"}
                </option>
                <option value="eti45">
                  {this.props.t("ethGlycol") + " 45%"}
                </option>
                <option value="eti50">
                  {this.props.t("ethGlycol") + " 50%"}
                </option>
                <option value="pro15">
                  {this.props.t("propGlycol") + " 15%"}
                </option>
                <option value="pro25">
                  {this.props.t("propGlycol") + " 25%"}
                </option>
                <option value="pro35">
                  {this.props.t("propGlycol") + " 35%"}
                </option>
                <option value="pro40">
                  {this.props.t("propGlycol") + " 40%"}
                </option>
                <option value="pro45">
                  {this.props.t("propGlycol") + " 45%"}
                </option>
                <option value="pro50">
                  {this.props.t("propGlycol") + " 50%"}
                </option>
                <option value="pro55">
                  {this.props.t("propGlycol") + " 55%"}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <td>{this.props.t("tIn_abbr") + " [°C]"}</td>
            <td id="formTingRisc" colSpan="2">
              <InputBox
                className={
                  this.props.visSett.blinkH || this.props.visSett.blinkFlowC
                    ? "blink"
                    : ""
                }
                key="twInH"
                name="twInH"
                onKeyPress={e => this.onKeyPress(e)}
                onChange={e => this.onChange(e)}
                value={this.props.cond.twInH}
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("tOut_abbr") + " [°C]"}</td>
            <td colSpan="2">
              <InputBox
                className={classNameTout}
                key="twOutH"
                name="twOutH"
                onKeyPress={e => this.onKeyPress(e)}
                onChange={e => this.onChange(e)}
                value={this._getTwOut()}
                isDisabled={this.props.visSett.flowC}
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td>{this.props.t("flowFluid") + " [l/h]"}</td>
            <td colSpan="2">
              <InputBox
                className={classNameQw}
                key="qwH"
                name="qwH"
                onKeyPress={e => this.onKeyPress(e)}
                onChange={e => this.onChange(e)}
                value={this._getQwH()}
                isDisabled={this.props.visSett.flowC}
                type="text"
              />
              <span
                data-tip={this.props.t("tip_flowC")}
                className="align-right"
              >
                <Btn
                  name="flowCbtn"
                  value={this.props.t("flowC")}
                  onClick={e => this.onChange(e)}
                  isChecked={this.props.visSett.flowC}
                  isDisabled={!this.props.visSett.isFlowCModePossible}
                  isBlue={true}
                  extraClassName="btn_width_m"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default withNamespaces()(TableHFluidSide);
