import React from "react";
import ReactTable from "react-table";
import { withNamespaces } from "react-i18next";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import "react-table/react-table.css";
import "./results.css";
const K_min_max_dec = require("../../assets/support/__k_min_max_dec"),
  K_backend = require("../../assets/support/__k_backend"),
  K_frontend = require("../../assets/support/__k_frontend"),
  K_varie = require("../../assets/support/__k_varie");

class Results extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minR: Math.floor((window.innerHeight - 33 * 2) / 33) + 1,
    };

    let visSett2 = this.props.visSett;
    visSett2.selectedVel = this.props.maccCalc[0].brs
      ? this.props.maccCalc[0].etiVel.length - 1
      : 0;
    this.props.setVisSett(visSett2);

    this._debouncedMouseMove = _.debounce(
      this._debouncedMouseMove.bind(this),
      100
    );
  }

  componentDidMount() {
    document.body.className = "body-ris";
    setTimeout(this.props.onSetOpen(false), 200);
  }

  _checkNan(val, dec) {
    let valRet = (+val).toFixed(dec);
    if (isNaN(val) || val === K_varie.undefined) valRet = K_varie.dataND;

    return valRet;
  }

  _computeClassRisMain() {
    let classname = "main",
      tableRisCol = this.props.visSett.tableRisCol;

    if (this.props.usrSett.showFceer) tableRisCol += 2;
    if (this.props.usrSett.showRe) tableRisCol += 2;
    if (this.props.usrSett.showNr) tableRisCol += 2;
    if (this.props.usrSett.showSpectrum) tableRisCol += 7;
    if (this.props.maccCalc[0].ducted) {
      tableRisCol += 2;
      if (this.props.usrSett.showNr) tableRisCol += 2;
      if (this.props.usrSett.showSpectrum) tableRisCol += 7;
    }

    if (tableRisCol >= this.props.visSett.tableRisCol)
      classname += " main_width_m";
    if (tableRisCol > 28) classname += " main_width_l";
    if (tableRisCol > 33) classname += " main_width_xl";

    return classname;
  }

  _computeClassRow(rowInfo) {
    let className = "";
    if (rowInfo.index === this.props.visSett.selectedRow)
      className += " selectedRow";
    if (rowInfo.original.overHalarm || rowInfo.original.inverterAlarm)
      className += " allarme";

    return className;
  }

  _debouncedMouseMove = (e) => {
    if (e.clientX < K_frontend.minClientX && e.clientY > K_frontend.minClientY)
      this.props.onSetOpen(true);
  };

  _generateTableHead() {
    let colC = [
        K_backend.modoResaC,
        K_backend.modoResaCH2p,
        K_backend.modoResaCH2pFlowC,
        K_backend.modoResaCH4p,
      ].includes(this.props.cond.modoResa),
      colH = [
        K_backend.modoResaCH2p,
        K_backend.modoResaCH2pFlowC,
        K_backend.modoResaCH4p,
        K_backend.modoResaH2p,
        K_backend.modoResaH4p,
      ].includes(this.props.cond.modoResa);

    return [
      {
        Header: "n°",
        accessor: "idRow",
        minWidth: K_frontend.col_width_s,
      },
      {
        Header: this.props.t("model"),
        accessor: "nameMac",
        minWidth: K_frontend.col_width_xl,
      },
      {
        Header: this.props.t("version"),
        accessor: "vers",
        minWidth: K_frontend.col_width_l,
      },
      {
        Header: this.props.t("coil"),
        accessor: "batt",
        className: "colBatt",
        minWidth: K_frontend.col_width_s,
      },
      {
        Header: this.props.t("speed"),
        accessor: "vel",
        minWidth: K_frontend.col_width_m,
      },
      {
        Header: <span data-tip={this.props.t("esp") + " [Pa]"}>ESP</span>,
        accessor: "esp",
        minWidth: K_frontend.col_width_s,
      },
      {
        Header: (
          <span data-tip={this.props.t("airFlow") + " [m\u00B3/h]"}>Qa</span>
        ),
        accessor: "qa",
        minWidth: K_frontend.col_width_m,
        Cell: (props) => {
          return this._showTip(props);
        },
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("resaTot") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [kW]"
            }
          >
            Pc
          </span>
        ),
        accessor: "resaTotC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("resaSens") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [kW]"
            }
          >
            Ps
          </span>
        ),
        accessor: "resaSensC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("resaLat") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [kW]"
            }
          >
            Pl
          </span>
        ),
        accessor: "resaLatC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("qCond") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [kg/h]"
            }
          >
            {this.props.t("condensate_abbr")}
          </span>
        ),
        accessor: "qCondC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("twIn") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [°C]"
            }
          >
            Tin
          </span>
        ),
        accessor: "twInC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("twOut") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [°C]"
            }
          >
            Tout
          </span>
        ),
        accessor: "twOutC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("qw") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [l/h]"
            }
          >
            Qw
          </span>
        ),
        accessor: "qwC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("dpw") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [kPa]"
            }
          >
            Dpc
          </span>
        ),
        accessor: "dpwC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              "N° Reynolds (" + this.props.t("cooling").toLowerCase() + ")"
            }
          >
            Re
          </span>
        ),
        accessor: "reC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC && this.props.usrSett.showRe,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("fanPwrIn") +
              " (" +
              this.props.t("cooling").toLowerCase() +
              ") [kW]"
            }
          >
            Pec
          </span>
        ),
        accessor: "wattC",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: <span data-tip={this.props.t("tip_fceer")}>FCEER</span>,
        accessor: "fceer",
        className: "td-blu",
        headerClassName: "th-blu",
        show: colC && this.props.usrSett.showFceer,
        minWidth: K_frontend.col_width_m,
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("resaTot") +
              " (" +
              this.props.t("heating").toLowerCase() +
              ") [kW]"
            }
          >
            Ph
          </span>
        ),
        accessor: "resaTotH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("twIn") +
              " (" +
              this.props.t("heating").toLowerCase() +
              ") [°C]"
            }
          >
            Tin
          </span>
        ),
        accessor: "twInH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("twOut") +
              " (" +
              this.props.t("heating").toLowerCase() +
              ") [°C]"
            }
          >
            Tout
          </span>
        ),
        accessor: "twOutH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("qw") +
              " (" +
              this.props.t("heating").toLowerCase() +
              ") [l/h]"
            }
          >
            Qw
          </span>
        ),
        accessor: "qwH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("dpw") +
              " (" +
              this.props.t("heating").toLowerCase() +
              ") [kPa]"
            }
          >
            Dph
          </span>
        ),
        accessor: "dpwH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              "N° Reynolds (" + this.props.t("heating").toLowerCase() + ")"
            }
          >
            Re
          </span>
        ),
        accessor: "reH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH && this.props.usrSett.showRe,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("fanPwrIn") +
              " (" +
              this.props.t("heating").toLowerCase() +
              ") [kW]"
            }
          >
            Peh
          </span>
        ),
        accessor: "wattH",
        className: "td-red",
        headerClassName: "th-red",
        show: colH,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: <span data-tip={this.props.t("tip_fccop")}>FCCOP</span>,
        accessor: "fccop",
        className: "td-red",
        headerClassName: "th-red",
        show: colH && this.props.usrSett.showFceer,
        minWidth: K_frontend.col_width_m,
      },
      {
        Header: (
          <span data-tip={this.props.t("lwA") + " (Outlet) [dBA]"}>Lw Out</span>
        ),
        accessor: "lwGlobOut",
        minWidth: K_frontend.col_width_m,
        Cell: (props) => {
          return this._showTip(props);
        },
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 125 [Hz] (Outlet)"}>
            125Hz
          </span>
        ),
        accessor: "lw125HzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 250 [Hz] (Outlet)"}>
            250Hz
          </span>
        ),
        accessor: "lw250HzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 500 [Hz] (Outlet)"}>
            500Hz
          </span>
        ),
        accessor: "lw500HzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 1k [Hz] (Outlet)"}>
            1kHz
          </span>
        ),
        accessor: "lw1kHzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 2k [Hz] (Outlet)"}>
            2kHz
          </span>
        ),
        accessor: "lw2kHzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 4k [Hz] (Outlet)"}>
            4kHz
          </span>
        ),
        accessor: "lw4kHzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lw") + " [dB] @ 8k [Hz] (Outlet)"}>
            8kHz
          </span>
        ),
        accessor: "lw8kHzOut",
        show: this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lpA") + " (Outlet) [dBA]"}>Lp Out</span>
        ),
        accessor: "lpGlobOut",
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: <span data-tip="Noise Rating (Outlet)">NR Out</span>,
        accessor: "nrOut",
        show: this.props.usrSett.showNr,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: <span data-tip="Noise Criteria (Outlet)">NC Out</span>,
        accessor: "ncOut",
        show: this.props.usrSett.showNr,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lwA") + " (Inlet + Radiated) [dBA]"}>
            Lw In
          </span>
        ),
        accessor: "lwGlobIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted,
        minWidth: K_frontend.col_width_m,
        Cell: (props) => {
          return this._showTip(props);
        },
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("lw") + " [dB] @ 125 [Hz] (Inlet + Radiated)"
            }
          >
            125Hz
          </span>
        ),
        accessor: "lw125HzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("lw") + " [dB] @ 250 [Hz] (Inlet + Radiated)"
            }
          >
            250Hz
          </span>
        ),
        accessor: "lw250HzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={
              this.props.t("lw") + " [dB] @ 500 [Hz] (Inlet + Radiated)"
            }
          >
            500Hz
          </span>
        ),
        accessor: "lw500HzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={this.props.t("lw") + " [dB] @ 1k [Hz] (Inlet + Radiated)"}
          >
            1kHz
          </span>
        ),
        accessor: "lw1kHzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={this.props.t("lw") + " [dB] @ 2k [Hz] (Inlet + Radiated)"}
          >
            2kHz
          </span>
        ),
        accessor: "lw2kHzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={this.props.t("lw") + " [dB] @ 4k [Hz] (Inlet + Radiated)"}
          >
            4kHz
          </span>
        ),
        accessor: "lw4kHzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span
            data-tip={this.props.t("lw") + " [dB] @ 8k [Hz] (Inlet + Radiated)"}
          >
            8kHz
          </span>
        ),
        accessor: "lw8kHzIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showSpectrum,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: (
          <span data-tip={this.props.t("lpA") + " (Inlet + Radiated) [dBA]"}>
            Lp In
          </span>
        ),
        accessor: "lpGlobIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: <span data-tip="Noise Rating (Inlet + Radiated)">NR In</span>,
        accessor: "nrIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showNr,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
      {
        Header: <span data-tip="Noise Criteria (Inlet + Radiated)">NC In</span>,
        accessor: "ncIn",
        className: "td-graL",
        headerClassName: "th-graL",
        show: this.props.maccCalc[0].ducted && this.props.usrSett.showNr,
        minWidth: K_frontend.col_width_m,
        sortMethod: (a, b) => Number(a) - Number(b),
      },
    ];
  }

  _generateTableData() {
    let tableData = [],
      idRow = 1;

    this.props.maccCalc.forEach((macCalc, idx) => {
      for (let velSel = 0; velSel < macCalc.etiVel.length; velSel++) {
        // velocità decrescenti
        let tableRow = {},
          velVis = macCalc.brs ? macCalc.etiVel.length - 1 - velSel : velSel;

        tableRow = {
          idRow: idRow,
          nameMac: macCalc.nome,
          vers: macCalc.vers,
          batt: macCalc.batteria,
          vel: this._parseVel(macCalc.etiVel[velVis]),
          esp: this.props.cond.esp,
          qa: this._checkNan(macCalc.qa[velVis], K_min_max_dec.decQa),
          resaTotC: this._checkNan(
            macCalc.resaTotC[velVis],
            K_min_max_dec.decResa
          ),
          resaSensC: this._checkNan(
            macCalc.resaSensC[velVis],
            K_min_max_dec.decResa
          ),
          resaLatC: this._checkNan(
            macCalc.resaLatC[velVis],
            K_min_max_dec.decResa
          ),
          qCondC: this._checkNan(macCalc.qCondC[velVis], K_min_max_dec.decResa),
          twInC: this._checkNan(macCalc.twInC[velVis], K_min_max_dec.decT),
          twOutC: this._checkNan(macCalc.twOutC[velVis], K_min_max_dec.decT),
          qwC: this._checkNan(macCalc.qwC[velVis], K_min_max_dec.decQw),
          dpwC: this._checkNan(macCalc.dpwC[velVis], K_min_max_dec.decDpw),
          reC: this._checkNan(macCalc.reC[velVis], K_min_max_dec.decRe),
          wattC: this._checkNan(macCalc.wattC[velVis], K_min_max_dec.deckWatt),
          fceer: macCalc.fceer[velVis],
          resaTotH: this._checkNan(
            macCalc.resaTotH[velVis],
            K_min_max_dec.decResa
          ),
          twInH: this._checkNan(macCalc.twInH[velVis], K_min_max_dec.decT),
          twOutH: this._checkNan(macCalc.twOutH[velVis], K_min_max_dec.decT),
          qwH: this._checkNan(macCalc.qwH[velVis], K_min_max_dec.decQw),
          dpwH: this._checkNan(macCalc.dpwH[velVis], K_min_max_dec.decDpw),
          reH: this._checkNan(macCalc.reH[velVis], K_min_max_dec.decRe),
          wattH: this._checkNan(macCalc.wattH[velVis], K_min_max_dec.deckWatt),
          fccop: macCalc.fccop[velVis],
          lwGlobOut: this._checkNan(
            macCalc.lwGlobOut[velVis],
            K_min_max_dec.decLw
          ),
          lw125HzOut: this._checkNan(
            macCalc.lw125HzOut[velVis],
            K_min_max_dec.decLw
          ),
          lw250HzOut: this._checkNan(
            macCalc.lw250HzOut[velVis],
            K_min_max_dec.decLw
          ),
          lw500HzOut: this._checkNan(
            macCalc.lw500HzOut[velVis],
            K_min_max_dec.decLw
          ),
          lw1kHzOut: this._checkNan(
            macCalc.lw1kHzOut[velVis],
            K_min_max_dec.decLw
          ),
          lw2kHzOut: this._checkNan(
            macCalc.lw2kHzOut[velVis],
            K_min_max_dec.decLw
          ),
          lw4kHzOut: this._checkNan(
            macCalc.lw4kHzOut[velVis],
            K_min_max_dec.decLw
          ),
          lw8kHzOut: this._checkNan(
            macCalc.lw8kHzOut[velVis],
            K_min_max_dec.decLw
          ),
          lpGlobOut: this._checkNan(
            macCalc.lpGlobOut[velVis],
            K_min_max_dec.decLw
          ),
          nrOut: this._checkNan(macCalc.nrOut[velVis], K_min_max_dec.decNr),
          ncOut: this._checkNan(macCalc.ncOut[velVis], K_min_max_dec.decNc),
          lwGlobIn: this._checkNan(
            macCalc.lwGlobIn[velVis],
            K_min_max_dec.decLw
          ),
          lw125HzIn: this._checkNan(
            macCalc.lw125HzIn[velVis],
            K_min_max_dec.decLw
          ),
          lw250HzIn: this._checkNan(
            macCalc.lw250HzIn[velVis],
            K_min_max_dec.decLw
          ),
          lw500HzIn: this._checkNan(
            macCalc.lw500HzIn[velVis],
            K_min_max_dec.decLw
          ),
          lw1kHzIn: this._checkNan(
            macCalc.lw1kHzIn[velVis],
            K_min_max_dec.decLw
          ),
          lw2kHzIn: this._checkNan(
            macCalc.lw2kHzIn[velVis],
            K_min_max_dec.decLw
          ),
          lw4kHzIn: this._checkNan(
            macCalc.lw4kHzIn[velVis],
            K_min_max_dec.decLw
          ),
          lw8kHzIn: this._checkNan(
            macCalc.lw8kHzIn[velVis],
            K_min_max_dec.decLw
          ),
          lpGlobIn: this._checkNan(
            macCalc.lpGlobIn[velVis],
            K_min_max_dec.decLw
          ),
          nrIn: this._checkNan(macCalc.nrIn[velVis], K_min_max_dec.decNr),
          ncIn: this._checkNan(macCalc.ncIn[velVis], K_min_max_dec.decNc),
          overHalarm: false,
          inverterAlarm: false,
          selMac: idx,
          selVel: velVis,
        };

        if (
          +macCalc.lwGlobIn[velVis] === K_min_max_dec.minLwEuro &&
          macCalc.euro
        )
          tableRow.lwGlobIn = K_backend.lwEuroStar;
        if (
          +macCalc.lwGlobOut[velVis] === K_min_max_dec.minLwEuro &&
          macCalc.euro
        )
          tableRow.lwGlobOut = K_backend.lwEuroStar;
        if (
          macCalc.whyEng[velVis] === K_backend.whyEngOverH ||
          macCalc.whyEng[velVis] === K_backend.whyEngInverterAlarm
        ) {
          idRow += 1;
          if (macCalc.whyEng[velVis] === K_backend.whyEngOverH)
            tableRow.overHalarm = true;
          if (macCalc.whyEng[velVis] === K_backend.whyEngInverterAlarm)
            tableRow.inverterAlarm = true;

          tableRow.qa = this.props.t("alarm");
          tableRow.resaTotC = K_varie.dataND;
          tableRow.resaSensC = K_varie.dataND;
          tableRow.resaLatC = K_varie.dataND;
          tableRow.qCondC = K_varie.dataND;
          tableRow.twInC = K_varie.dataND;
          tableRow.twOutC = K_varie.dataND;
          tableRow.qwC = K_varie.dataND;
          tableRow.dpwC = K_varie.dataND;
          tableRow.reC = K_varie.dataND;
          tableRow.wattC = K_varie.dataND;
          tableRow.fceer = K_varie.dataND;
          tableRow.resaTotH = K_varie.dataND;
          tableRow.twInH = K_varie.dataND;
          tableRow.twOutH = K_varie.dataND;
          tableRow.qwH = K_varie.dataND;
          tableRow.dpwH = K_varie.dataND;
          tableRow.reH = K_varie.dataND;
          tableRow.wattH = K_varie.dataND;
          tableRow.fccop = K_varie.dataND;
          tableRow.lwGlobOut = K_varie.dataND;
          tableRow.lpGlobOut = K_varie.dataND;
          tableRow.nrOut = K_varie.dataND;
          tableRow.ncOut = K_varie.dataND;
          tableRow.lw125HzOut = K_varie.dataND;
          tableRow.lw250HzOut = K_varie.dataND;
          tableRow.lw500HzOut = K_varie.dataND;
          tableRow.lw1kHzOut = K_varie.dataND;
          tableRow.lw2kHzOut = K_varie.dataND;
          tableRow.lw4kHzOut = K_varie.dataND;
          tableRow.lw8kHzOut = K_varie.dataND;
          tableRow.lwGlobIn = K_varie.dataND;
          tableRow.lpGlobIn = K_varie.dataND;
          tableRow.nrIn = K_varie.dataND;
          tableRow.ncIn = K_varie.dataND;
          tableRow.lw125HzIn = K_varie.dataND;
          tableRow.lw250HzIn = K_varie.dataND;
          tableRow.lw500HzIn = K_varie.dataND;
          tableRow.lw1kHzIn = K_varie.dataND;
          tableRow.lw2kHzIn = K_varie.dataND;
          tableRow.lw4kHzIn = K_varie.dataND;
          tableRow.lw8kHzIn = K_varie.dataND;

          tableData.push(tableRow);
        }
        if (macCalc.whyQa[velVis] > K_backend.whyQaLow) {
          idRow += 1;
          tableData.push(tableRow);
        }
      }

      return;
    });

    return tableData;
  }

  _onMouseMove = (e) => {
    e.persist();
    this._debouncedMouseMove(e);
  };

  _parseVel(vel) {
    if (this.props.maccCalc[0].brs)
      return String(parseFloat(vel).toFixed(K_min_max_dec.decVel));
    else return vel;
  }

  _selectRow(rowInfo) {
    let visSett2 = this.props.visSett;
    visSett2.selectedMac = rowInfo.original.selMac;
    visSett2.selectedRow = rowInfo.index;
    visSett2.selectedVel = rowInfo.original.selVel;

    this.props.setVisSett(visSett2);
  }

  _showTip(props) {
    let tipDiv;

    if (props.value === K_backend.lwEuroStar) {
      tipDiv = (
        <React.Fragment>
          <div>
            <ReactTooltip
              id="minLw"
              effect="solid"
              place="left"
              delayShow={10}
              disable={false}
              className="tooltip-react-minLw"
            />
            <span data-for="minLw" data-tip={this.props.t("notes_minLw")}>
              {props.value}
            </span>
          </div>
        </React.Fragment>
      );
    } else if (props.original.overHalarm) {
      tipDiv = (
        <React.Fragment>
          <div>
            <ReactTooltip
              id="overH"
              effect="solid"
              place="right"
              delayShow={10}
              disable={false}
              className="tooltip-react-alarm"
            />
            <span data-for="overH" data-tip={this.props.t("alarm_overH")}>
              {props.value}
            </span>
          </div>
        </React.Fragment>
      );
    } else if (props.original.inverterAlarm) {
      tipDiv = (
        <React.Fragment>
          <div>
            <ReactTooltip
              id="inverter"
              effect="solid"
              place="right"
              delayShow={10}
              disable={false}
              className="tooltip-react-alarm"
            />
            <span data-for="inverter" data-tip={this.props.t("alarm_inverter")}>
              {props.value}
            </span>
          </div>
        </React.Fragment>
      );
    } else {
      tipDiv = <React.Fragment>{props.value}</React.Fragment>;
    }

    return tipDiv;
  }

  render() {
    return (
      <>
        <div
          id="wrapper-ris"
          className="noselect"
          onMouseMove={this._onMouseMove}
        >
          <ReactTooltip
            effect="solid"
            place="bottom"
            delayShow={10}
            disable={!this.props.usrSett.tipsEnabled}
            className="tooltip-react"
          />
          <div className={this._computeClassRisMain()}>
            <div className="container-fluid">
              <div id="results" className="cell">
                <ReactTable
                  data={this._generateTableData()}
                  columns={this._generateTableHead()}
                  showPagination={false}
                  defaultPageSize={1000}
                  minRows={this.state.minR}
                  className="-striped -highlight"
                  noDataText=""
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          e.persist();
                          this._selectRow(rowInfo);
                          //this.setState({ selectedRow: rowInfo.index });
                        },
                        className: this._computeClassRow(rowInfo),
                      };
                    } else return {};
                  }}
                  defaultSorted={[
                    {
                      id: "idRow",
                      desc: false,
                    },
                  ]}
                  style={{
                    height: "99vh",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withNamespaces()(Results);
